exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assets/fonts/proxima-nova-black.woff"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./assets/fonts/proxima-nova-bold.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./assets/fonts/proxima-nova-regular-webfont.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./assets/fonts/proxima-nova-regular.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./assets/fonts/proxima-nova-thin.woff"));

// Module
exports.push([module.id, ".App {\n  text-align: center;\n}\n\n.App-logo {\n  animation: App-logo-spin infinite 20s linear;\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n@font-face {\n  font-family: 'proxima-nova';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'proxima-nova';\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'proxima-nova';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'proxima-nova';\n  font-weight: 400;\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'proxima-nova';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL___4___ + ") format('woff');\n}", ""]);

