import React, { useState, useContext } from "react";
import {
  LoggedInUser,
  StoreConextInterface,
  UserTest,
  SeenVideo,
  Question,
  Flashcard,
  Category,
  ModuleTestFlashcards,
  ModuleAccess,
} from "../types";
import { FirebaseContext } from "./firebaseContext";
import { UserContext } from "./userContext";
import { MODULE1, MODULE_CATEGORIES, ROUTES, AdminAccounts, TEST_ONLY } from "../constants";
import { TestContext } from "./testContext";
import { useHistory } from "react-router-dom";
import { VIDEOS } from "../data-constants";

interface StoreContextProps {
  children: React.ReactNode;
}

export const StoreContext = React.createContext<StoreConextInterface>({
  loginUser: (email: string, password: string) => {},
  logoutUser: () => {},
  loggedInUser: undefined,
  loginError: "",
  isLoading: false,
  modules: [],
  moduleAccess: {},
  activeModule: null,
  changeActiveModule: () => {},
  isAdmin: false,
  appError: "",
  appInfo: "",
  moduleVideos: [],
  userTestData: [],
  userVideoData: [],
  moduleTestFlashcards: {
    test: [],
    flashcards: [],
  },
  moduleTestMeta: { tag: "", name: "", categories: [] },
  removeTestResult: (categorId: number) => {},
  removeTestResults: (timestamps: number[]) => {},
  addTestResult: (userTest: UserTest) => {},
  addVideo: (video: SeenVideo) => {},
  removeVideo: (id: string) => {},
  categoryQuestionCount: (categoryId: number) => {},
  sendPasswordResetEmail: (email: string) => {},
  dissmissAppInfo: () => {},
  updateTestAndFlashcards: (moduleName: string, data: ModuleTestFlashcards) => {},
  setError: Function,
  setInfo: Function,
});

function StoreProvider(props: StoreContextProps) {
  const history = useHistory();
  const { firebase } = useContext(FirebaseContext);
  const { moduleTests, setTestModules, updateTestFlashcardsModule } = useContext(TestContext);
  const {
    userModuleTestInfo,
    clearTestResult,
    clearTestResults,
    addUserTest,
    addUserVideo,
    removeUserVideo,
    getUserTestData,
  } = useContext(UserContext);
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>("");
  const [modules, setModules] = useState<string[]>([]);
  const [activeModule, setActiveModule] = useState<string | null>(MODULE1);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [appError, setError] = useState<string>("");
  const [appInfo, setInfo] = useState<string>("");
  const [moduleAccess, setModuleAccess] = useState<ModuleAccess>({});

  const changeActiveModule = (mod: string) => {
    setActiveModule(mod);
  };

  const logoutUser = () => {
    firebase.doSignOut();
    setLoggedInUser(undefined);
    setError("");
    setInfo("");
  };

  const addTestResult = (userTest: UserTest) => {
    addUserTest(activeModule, userTest);
  };

  const removeTestResult = (timestamp: number) => {
    clearTestResult(activeModule, timestamp);
  };

  const removeTestResults = (timestamps: number[]) => {
    clearTestResults(activeModule, timestamps);
  };

  const addVideo = (video: SeenVideo) => {
    addUserVideo(activeModule, video);
  };
  const removeVideo = (id: string) => {
    removeUserVideo(activeModule, id);
  };

  const categoryQuestionCount = (categoryId: number): number => {
    const mod = activeModule && moduleTests[activeModule];
    if (!mod) return 0;
    const questions = (activeModule && moduleTests[activeModule].test) || [];
    return questions.filter((q: Question) => q.cat === categoryId).length;
  };

  const sendPasswordResetEmail = (email: string) => {
    firebase.doPasswordReset(email);
    setInfo(`Reset password sent to ${email}`);
  };

  const dissmissAppInfo = () => {
    setInfo("");
    setError("");
  };

  const updateTestAndFlashcards = (moduleName: string, testAndFlashcards: ModuleTestFlashcards) => {
    setInfo("Test and/or Flashcards Saved!");
    updateTestFlashcardsModule(moduleName, testAndFlashcards);
    setTimeout(() => dissmissAppInfo(), 2000);
  };

  const loginUser = (email: string, password: string) => {
    setLoading(true);
    firebase.doSignInWithEmailAndPassword(
      email,
      password,
      (err: { message: string }, loggedInUser: LoggedInUser | undefined) => {
        setLoading(false);
        if (err) {
          setError(err.message);
          return setLoginError(err.message);
        }
        if (!!!loggedInUser) return setLoginError("User Not Found");
        setLoggedInUser(loggedInUser);
        // get user modules from firebase
        firebase.doRetrieveUserModules(loggedInUser.user.uid, (userModules: ModuleAccess) => {
          console.log('setting user access modules', userModules)

          const moduleStrings = Object.keys(userModules)
          setModules(moduleStrings);
          setTestModules(moduleStrings);
          
          setModuleAccess(userModules)

          const activeMod = moduleStrings.length > 0 ? moduleStrings[0] : null;
          setActiveModule(activeMod);
          if (!activeMod) {
            setError("No purchased modules found!")
            logoutUser()
          };

          getUserTestData(loggedInUser.user.uid);
          if (loggedInUser && loggedInUser.user && AdminAccounts.includes(loggedInUser.user.uid)) {
            setIsAdmin(true);
          }

          var accessIsTestOnly = userModules[moduleStrings[0]] === TEST_ONLY
          if(accessIsTestOnly){
            history.push({ pathname: ROUTES.TESTS });
          }else{
            history.push({ pathname: ROUTES.HOME });
          }
        });
      },
    );
  };

  const store = {
    loginUser,
    logoutUser,
    loggedInUser,
    loginError,
    isLoading,
    modules,
    moduleAccess,
    activeModule,
    changeActiveModule,
    isAdmin,
    appError,
    userTestData: (activeModule && userModuleTestInfo[activeModule] && userModuleTestInfo[activeModule].tests) || [],
    userVideoData: (activeModule && userModuleTestInfo[activeModule] && userModuleTestInfo[activeModule].videos) || [],
    moduleTestFlashcards: (activeModule && moduleTests[activeModule]) || {
      test: [] as Question[],
      flashcards: [] as Flashcard[],
    },
    moduleTestMeta: (activeModule && MODULE_CATEGORIES[activeModule]) || {
      tag: "unk",
      name: "",
      categories: [] as Category[],
    },
    moduleVideos: (activeModule && VIDEOS[activeModule]) || [],
    addTestResult,
    removeTestResult,
    removeTestResults,
    addVideo,
    removeVideo,
    categoryQuestionCount,
    sendPasswordResetEmail,
    appInfo,
    dissmissAppInfo,
    updateTestAndFlashcards,
    setError,
    setInfo,
  };

  return <StoreContext.Provider value={store}>{props.children}</StoreContext.Provider>;
}

export default StoreProvider;
