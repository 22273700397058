import React from "react";
import { Route } from "react-router-dom";
import { Redirect, RouteComponentProps } from "react-router";
import { ROUTES } from "../constants";
import { useContext } from "react";
import { StoreContext } from "../contexts/storeContext";

interface AuthenticatedRouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path: string;
}

export const AuthenticatedRoute = (allProps: AuthenticatedRouteProps) => {
  const { loggedInUser } = useContext(StoreContext);
  return (
    <Route
      render={props => (loggedInUser ? <allProps.component {...props} /> : <Redirect to={ROUTES.HOME} />)}
    />
  );
};

export default AuthenticatedRoute;
