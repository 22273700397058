import React, { useContext, useState, useEffect, useRef } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { Button, regText, largeText, RoundButton, borderColor, deviceSize, TimeElapsed } from "./common";
import { StoreConextInterface, Question, Category, UserTest, UserQuestionAnswers, Answer } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import { TakeTestSummary } from "./take-test-summary";
import { randomPicker } from "../helpers/structures";
import { ANSWER_LETTERS, EXAM_TYPES } from "../constants";

interface ModuleCategory {
  moduleName: string;
  category?: Category;
  randomized: boolean;
}
const StyledTakeTest = styled.div`
  padding-bottom: 5rem;

  > button {
    margin-top: 0.25rem;
    width: 150px;
  }

  > div:first-of-type {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    > button {
      width: unset;
      padding: 0.5rem;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    font-size: ${regText};

    > div:first-of-type {
      font-size: ${largeText};
      margin-bottom: 1rem;
    }

    &:not(:first-of-type) {
      display: flex;
      justify-content: space-between;

      @media ${deviceSize.mobileL} {
        width: 100%;
      }
    }

    > p {
      padding: 1rem;
      &:nth-of-type(1){
        border-top: 2px solid ${borderColor};
        margin-bottom: 0;
      }
      &:nth-of-type(2){
        margin-top: 0;
      }
    }
  }
`;

interface ShuffledAnswers {
  [questionId: number]: Answer[]
}

let shuffledAnswers: ShuffledAnswers = {}

const shuffleArray = (array: Answer[]) => {
  let newArray = array.slice()
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray.filter(x => x.value !== undefined && x.value.trim() !== '')
}


interface TestConfig {
  catId: number;
  rand: boolean;
  exist: number;
  chap: number;
}

export const TakeTest = (routeProps?: RouteComponentProps) => {
  const history = useHistory();
  const location = useLocation();
  const { moduleTestFlashcards, moduleTestMeta, userTestData, addTestResult } = useContext<StoreConextInterface>(
    StoreContext,
  );
  const [testConfig, setTestConfig] = useState<TestConfig>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [moduleInfo, setModuleInfo] = useState<ModuleCategory>();
  const [userTest, setUserTest] = useState<UserTest>({
    timestamp: new Date().getTime(),
    chapterId: 0,
    categoryId: 0,
    randomized: false,
    correctPct: 0,
    correctAnswers: 0,
    wrongPct: 0,
    wrongAnswers: 0,
    questions: [],
    questionsAnswered: 0,
    questionsTotal: questions.length,
    type: EXAM_TYPES.REG_EXAM,
    elapsed: 0,
  });
  const [index, setIndex] = useState<number>(0);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [autoForeward, setAutoForeward] = useState<boolean>(false);
  const [startTime] = useState<number>(new Date().getTime());
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const savedTimer = useRef<number>();

  let correctAnswerText: string | undefined
  if (!!!testConfig) {
    correctAnswerText = undefined;
    shuffledAnswers = {}
  }

  useEffect(() => {
    if (!!!testConfig) {
      const items = location.pathname.replace("/take/", "").split("/");
      const config: TestConfig = {
        catId: Number(items[0]),
        rand: Boolean(Number(items[1])),
        exist: Number(items[2]),
        chap: Number(items[3])
      };

      let props: any
      if (routeProps && routeProps.location.state) {
        props = routeProps.location.state
        console.log('we got porps')
      }

      const test = moduleTestFlashcards.test;
      let questions: Question[] = test;

      if (config.catId !== 0) {
        questions = test.filter(t => t.cat === config.catId);
      }
      if (config.chap !== 0) {
        questions = test.filter(t => t.chp === config.chap);
      }
      if (props && props.questionSet) {
        questions = test.filter(t => props.questionSet.includes(t.id));
      }

      if (config.rand) {
        const category = moduleTestMeta.categories.find(c => c.id === config.catId)
        // only category randized test
        let questionIds = randomPicker(questions.map(q => q.id), category ? category.randNum : 30);
        if (config.catId === 0) {
          // full rand test pick from cats
          questionIds = moduleTestMeta.categories.reduce(
            (p, c) => [...p, ...randomPicker(test.filter(t => t.cat === c.id).map(q => q.id), c.randNum)],
            [] as number[],
          );
        }
        questions = questionIds.map(id => questions.find(t => t.id === id)) as Question[];
      }
      let found = null;
      if (config.exist) {
        found = userTestData.find(t => t.timestamp === config.exist);
        const foundQuestions = found ? found.questions.map(q => test.find(t => t.id === q.questionId)) : questions;
        questions = foundQuestions as Question[];
      }
      let type = EXAM_TYPES.REG_EXAM;
      if (config.rand) {
        type = config.catId !== 0 ? EXAM_TYPES.RAND_CAT : EXAM_TYPES.RAND_EXAM;
      }
      if (!config.rand && config.catId !== 0) type = EXAM_TYPES.REG_CAT;
      if (config.chap !== 0) type = EXAM_TYPES.REG_CHP;

      if (props && props.type !== undefined) {
        console.log(props.type)
        switch (props.type) {
          case 0:
            type = EXAM_TYPES.CORR_REVIEW
            break;
          case 1:
            type = EXAM_TYPES.MISS_REVIEW
            break;
          case 2:
            type = EXAM_TYPES.SKIP_REVIEW
            break;
          case 3:
            type = EXAM_TYPES.MISS_REVIEW
            break;
          default:
            type = EXAM_TYPES.MISS_REVIEW
            break;
        }
      }

      console.log(type)
      const sortedQuestions = questions.sort((a, b) => (a.id > b.id ? 1 : -1));
      setQuestions(sortedQuestions);
      let newSummary = !!found
        ? found
        : {
          ...userTest,
          type,
          chapterId: config.chap,
          categoryId: config.catId,
          randomized: config.rand,
          questions: sortedQuestions
            .filter(x => x)
            .map(q => ({
              categoryId: q.cat,
              chapterId: q.chp,
              questionId: q.id,
              answer: "",
              isCorrect: false,
            }))
            .sort((a, b) => (a.questionId > b.questionId ? 1 : -1)),
        };
      setUserTest(newSummary);
      setModuleInfo({
        moduleName: moduleTestMeta.name,
        randomized: config.rand,
        category: config.catId ? moduleTestMeta.categories.find(c => c.id === config.catId) : undefined,
      });
      setTestConfig(config);
      const indexQuestion = sortedQuestions[index];
      const userTestAnswer = newSummary.questions.find(q => q.questionId === indexQuestion.id);
      setUserAnswer(userTestAnswer ? userTestAnswer.answer : "");
    }
  }, []);

  useEffect(() => {
    const indexQuestion = questions[index];
    const userTestAnswer = userTest.questions.find(q => q.questionId === indexQuestion.id);
    setUserAnswer(userTestAnswer ? userTestAnswer.answer : "");
  }, [index, questions, userTest])

  useEffect(() => {
    savedTimer.current = setInterval(() => {
      const elapsed = (new Date().getTime() - startTime) / 1000;
      setTimeElapsed(elapsed);
    }, 1000);
    return () => clearInterval(savedTimer.current);
  }, [startTime]);

  const updateIndex = (index: number) => {
    setIndex(index);
    setShowAnswer(false);
    correctAnswerText = undefined
    const question = questions[index];
    const userValue = userTest.questions.find(q => q.questionId === question.id);
    userValue ? setUserAnswer(userValue.answer) : setUserAnswer("");
  };

  const gotoQuestion = (questionId: number) => {
    const index = questions.findIndex(q => q.id === questionId);
    updateIndex(index);
    setShowResults(false);
  };

  const answerQuestion = (index: number, answerValue: string) => {
    setUserAnswer(answerValue);
    const question = questions[index];
    const userQuestionAnswer: UserQuestionAnswers = {
      categoryId: question.cat,
      chapterId: question.chp,
      questionId: question.id,
      answer: answerValue,
      isCorrect: answerValue === question.answer,
    };
    userTest.questions = [...userTest.questions.filter(q => q.questionId !== question.id), userQuestionAnswer].sort(
      (a, b) => (a.questionId > b.questionId ? 1 : -1),
    );
    const correctWrong = userTest.questions.reduce(
      (p, q) => (q.isCorrect ? { ...p, c: p.c + 1 } : q.answer === "" ? { ...p, u: p.u + 1 } : { ...p, w: p.w + 1 }),
      {
        c: 0,
        w: 0,
        u: 0,
      },
    );

    userTest.correctAnswers = correctWrong.c;
    userTest.correctPct = !!correctWrong.c ? Number(((correctWrong.c / questions.length) * 100).toFixed(1)) : 0;
    userTest.wrongAnswers = correctWrong.w;
    userTest.wrongPct = !!correctWrong.w ? Number(((correctWrong.w / questions.length) * 100).toFixed(1)) : 0;
    userTest.questionsTotal = questions.length;
    userTest.questionsAnswered = correctWrong.c + correctWrong.w;
    userTest.elapsed = timeElapsed;
    setUserTest(userTest);
    addTestResult(userTest);
    if (autoForeward && index !== questions.length - 1) updateIndex(index + 1);
  };

  if (index !== undefined && questions[index] !== undefined && shuffledAnswers[questions[index].id] === undefined) {
    shuffledAnswers[questions[index].id] = shuffleArray(questions[index].answers)
  }


  if (correctAnswerText === undefined) {
    let answerObj
    if (questions.length > 0 && index !== undefined) {
      answerObj = questions[index].answers.find(x => x.name === questions[index].answer)
      if (answerObj !== undefined) {
        correctAnswerText = answerObj.value
      }
    }
  }

  return (
    <StyledTakeTest>
      <Button secondary onClick={() => history.goBack()}>
        Exit Taking Test
      </Button>
      <TestHeader moduleInfo={moduleInfo} testConfig={testConfig} />
      <div>
        <Button disabled={index === 0} onClick={() => updateIndex(0)}>
          {"<<"}
        </Button>
        <Button disabled={index === 0} onClick={() => (index !== 0 ? updateIndex(index - 1) : updateIndex(0))}>
          Back
        </Button>
        <Button noHover={showAnswer} secondary={showAnswer} onClick={() => setShowAnswer(!showAnswer)}>
          {showAnswer ? "Hide " : "Show "} Answer
        </Button>
        <Button noHover={autoForeward} secondary={autoForeward} onClick={() => setAutoForeward(!autoForeward)}>
          Auto Foreward {autoForeward ? " Off" : " On"}
        </Button>
        <Button
          disabled={index === questions.length - 1}
          onClick={() => (index !== questions.length - 1 ? updateIndex(index + 1) : updateIndex(index))}
        >
          Next
        </Button>
        <Button disabled={index === questions.length - 1} onClick={() => updateIndex(questions.length - 1)}>
          {">>"}
        </Button>
        <Button noHover={showResults} secondary={showResults} onClick={() => setShowResults(!showResults)}>
          {showResults ? "Hide " : "Show "} Results
        </Button>
      </div>
      <div>
        <span style={{ marginRight: `1rem` }}>
          Question: {index + 1} / {questions.length}
        </span>
        <TimeElapsed elapsed={timeElapsed} />
      </div>
      {showResults && <TakeTestSummary userTest={userTest} gotoQuestion={gotoQuestion} />}
      {!showResults && (
        <div>
          <div>{questions.length > 0 && questions[index].q}</div>
          <div>
            {questions.length > 0 &&
              shuffledAnswers[questions[index].id].map((a: Answer, i) => (
                <div key={a.name} style={{ padding: `0.2rem 0` }}>
                  <RoundButton selected={userAnswer === a.name} onClick={() => answerQuestion(index, a.name)}>
                    {ANSWER_LETTERS[i]}
                  </RoundButton>
                  <span style={{ paddingLeft: `1rem` }}>{a.value}</span>
                </div>
              ))}
          </div>
          {showAnswer && <>
            <p>
              Answer: {correctAnswerText}
            </p>
            { questions[index].explanation !== undefined && questions[index].explanation.trim() !== '' && <p>
              Explanation: {questions[index].explanation}
            </p>}
          </>}
        </div>
      )}
    </StyledTakeTest>
  );
};

interface TestHeaderProps {
  moduleInfo?: ModuleCategory;
  testConfig?: TestConfig;
}
const StyledTestHeader = styled.span`
  font-size: ${regText};
  margin-left: 2rem;
  font-weight: 600;
`;

const TestHeader = ({ moduleInfo, testConfig }: TestHeaderProps) => {
  let testHeader = "";
  const isRand = testConfig && testConfig.rand;
  const isExam = testConfig && testConfig.catId === 0 && testConfig.chap === 0;
  if (isRand) testHeader = "Randomized ";
  if (testConfig && testConfig.catId !== 0) {
    const name = `Category: ${moduleInfo && moduleInfo.category && moduleInfo.category.name}`;
    testHeader = testHeader.length > 0 ? `${testHeader}, ${name}` : `${name}`;
  }
  if (testConfig && testConfig.chap !== 0) {
    const name = `Chapter ${testConfig.chap}`;
    testHeader = testHeader.length > 0 ? `${testHeader}, ${name}` : `${name}`;
  }
  if (isExam) {
    const name = `Exam`;
    testHeader = testHeader.length > 0 ? `${testHeader}, ${name}` : `Full ${name}`;
  }

  return <StyledTestHeader>{testHeader}</StyledTestHeader>;
};
