import app from "firebase/app";
import { Flashcard, Question, ModuleTestFlashcards, UserPurchases } from "../types";
import { 
  COLLECTIONS, AdminAccounts,
  MODULE1, MODULE2, MODULE3, MODULE4, MODULE5, FULL_ACCESS, TEST_ONLY,
} from "../constants";

require("firebase/auth");
require("firebase/database");
require("firebase/firestore");

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

interface ModuleTestQandFlash {
  [moduleName: string]: { test: Question[]; flashcards: Flashcard[] };
}

class Firebase {
  auth: firebase.auth.Auth;
  database: firebase.database.Database;
  firestore: firebase.firestore.Firestore;
  isLoggedIn: boolean;

  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.database = app.database();
    this.firestore = app.firestore();
    this.isLoggedIn = false;
  }

  doStoreTestFlashcards = (moduleName: string, data: ModuleTestFlashcards) => {
    this.firestore
      .collection(COLLECTIONS.MODULES)
      .doc(moduleName)
      .set(data)
      .catch(e => console.log(e));
  };

  doStoreUserData = (id: string, data: object, callback: Function) => {
    if (id) {
      this.firestore
        .collection(COLLECTIONS.USER_DATA)
        .doc(id)
        .set({
          ...data,
        })
        .then(() => callback())
        .catch(error => {
          console.error(error);
        });
    }
  };

  doRetrieveAllUserIds(callback: Function) {
    this.firestore
      .collection(COLLECTIONS.USER_MODULES)
      .get()
      .then(doc => {
        callback(doc.docs.map(d => d.data()));
      });
  }

  doRetrieveUserModules(id: string, callback: Function) {
    this.firestore
      .collection(COLLECTIONS.USER_MODULES)
      .doc(id)
      .get()
      .then(doc => {
        let availableModules = {};
        let userModuleTestInfo2 = doc.data();

        if (userModuleTestInfo2 && userModuleTestInfo2.SKU1) {
          availableModules = {...availableModules, [MODULE1]: FULL_ACCESS}
        }else if (userModuleTestInfo2 && userModuleTestInfo2.GLLAH08) {
          availableModules = {...availableModules, [MODULE1]: TEST_ONLY}
        }

        if (userModuleTestInfo2 && userModuleTestInfo2.SKU2) {
          availableModules = {...availableModules, [MODULE2]: FULL_ACCESS}
        } else if (userModuleTestInfo2 && userModuleTestInfo2.LF07) {
          availableModules = {...availableModules, [MODULE2]: TEST_ONLY}
        }
        
        if (userModuleTestInfo2 && userModuleTestInfo2.SKU3) {
          availableModules = {...availableModules, [MODULE3]: FULL_ACCESS}
        } else if (userModuleTestInfo2 && userModuleTestInfo2.GLPC06) {
          availableModules = {...availableModules, [MODULE3]: TEST_ONLY}
        }

        if (userModuleTestInfo2 && userModuleTestInfo2.SKU4) {
          availableModules = {...availableModules, [MODULE4]: FULL_ACCESS}
        } else if (userModuleTestInfo2 && userModuleTestInfo2.PLPC05) {
          availableModules = {...availableModules, [MODULE4]: TEST_ONLY}
        }

        if (userModuleTestInfo2 && userModuleTestInfo2.SKU5) {
          availableModules = {...availableModules, [MODULE5]: FULL_ACCESS}
        }

        if (AdminAccounts.includes(id)) {
          availableModules = {
            [MODULE1]: FULL_ACCESS,
            [MODULE2]: FULL_ACCESS,
            [MODULE3]: FULL_ACCESS,
            [MODULE4]: FULL_ACCESS,
        }
      }
        callback(availableModules);
      });
  }

  doRetrieveUserData(id: string, callback: Function) {
    this.firestore
      .collection(COLLECTIONS.USER_DATA)
      .doc(id)
      .get()
      .then(doc => {
        const userModuleTestInfo = doc.data();
        doc.exists ? callback(userModuleTestInfo) : callback({});
      });
  }

  doUpdateUserPurchases(userPurchases: UserPurchases, callback: Function) {
    const email = userPurchases.email;
    this.firestore
      .collection(COLLECTIONS.USER_MODULES)
      .where(`email`, "==", email)
      .get()
      .then(snapshot => {
        if (snapshot.size !== 1) {
          console.log("ERROR - multiple existing users with same email address detected");
        }
        snapshot.forEach(doc => {
          let docData = doc.data();

          console.log("existing data", docData);
          let existingDocID = doc.id;
          console.log("user id", existingDocID);
          let newPurchasesData = { ...userPurchases };
          this.firestore
            .collection(COLLECTIONS.USER_MODULES)
            .doc(existingDocID)
            .set(newPurchasesData)
            .then(() => callback())
        });
      })
      .catch(e => callback(e));
  }

  doAddNewUserPurchases(userPurchases: UserPurchases, callback: Function) {
    const password = String(userPurchases.email).toLowerCase();
    this.auth
      .createUserWithEmailAndPassword(String(userPurchases.email), password)
      .then(success => {
        if (success && success.user) {
          this.firestore
            .collection(COLLECTIONS.USER_MODULES)
            .doc(success.user.uid)
            .set(userPurchases)
            .then(() => callback())
        } else {
          console.error("Could not create user");
        }
      })
      .catch(e => callback(e));
  }

  doSendForgotPasswordEmail(emailAddress: string) {
    this.auth
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        console.log("Password Reset email sent!");
      })
      .catch(function(error) {
        console.error("Password Reset email failed!");
      });
  }

  doRetreiveTests(moduleNames: string[], callback: Function) {
    const errorState = moduleNames.reduce((p, m) => ({ ...p, [m]: { test: [], flashcards: [] } }), {});
    try {
      const testQandFPromises: Promise<ModuleTestQandFlash>[] = moduleNames.map(m =>
        this.fetchTestQandFlashcards(m, this.firestore),
      );

      Promise.all(testQandFPromises)
        .then(tests => {
          let moduleTests = tests.reduce((p, t) => Object.assign(p, t), {});
          callback(moduleTests);
        })
        .catch((e: any) => {
          callback(errorState);
        });
    } catch (e) {
      callback(errorState);
    }
  }

  fetchTestQandFlashcards(moduleName: string, firestore: app.firestore.Firestore): Promise<ModuleTestQandFlash> {
    return new Promise(function (resolve, reject) {
      try {
        firestore
          .collection(COLLECTIONS.MODULES)
          .doc(moduleName)
          .get()
          .then((fireDataSnapshot: any) => {
            let fireData = fireDataSnapshot.data();
            resolve({ [moduleName]: fireData });
          })
          .catch((e: any) => {
            console.error("Error:", e);
            reject(e);
          });
      } catch (e) {
        console.error("Error:", e);
        reject(e);
      }
    });
  }

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email: string, password: string, callback: Function) =>
    this.auth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.isLoggedIn = true;
        callback(null, user);
      })
      .catch(e => {
        callback(e, null);
      })
      .finally(() => {
        console.log("login attempted");
      });
  doSignOut = () =>
    this.auth
      .signOut()
      .then(() => (this.isLoggedIn = false))
      .catch(() => console.error("user could not logout"));
  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password: string) =>
    this.auth.currentUser ? this.auth.currentUser.updatePassword(password) : console.error("user not active");
  doPasswordUpdateToDefaultPassword = (password: string) =>
    this.auth.currentUser ? this.auth.currentUser.updatePassword(password) : console.error("user not active");
}

export default Firebase;
