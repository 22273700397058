import React, { useContext, useState } from "react";
import { StoreConextInterface, Category } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import {
  regText,
  smallText,
  Button,
  secondaryBackgroundColor,
  regBorderRadius,
} from "./common";
import { ROUTES } from "../constants";
import { useHistory } from "react-router-dom";
import { NewTestCategory } from './test-category'
import { TestHistory } from "./test-history";

const StyledTest = styled.div`
  margin: 0 10%;
`;

const StyledTestHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: ${secondaryBackgroundColor};
  border-radius: ${regBorderRadius};
  margin-top: 15px; 
  padding: 15px 0; 

  button {
    width: 48%;
    font-size: ${regText};
  }
`;

const StyledInfoBox = styled.div`
  margin-top: 1rem;
  border-radius: 10px;
  background-color: #b6edff80;
  border: 2px solid #517ab380;
  padding: 15px 0;
  position: relative;

  >div:nth-child(1){
    width: 100%;
    text-align: center;
    font-weight: 700;

    ${(props: any) => props.closed ?
    'cursor: pointer' : 'cursor: inherit'
  }
  }

  >div:nth-child(2){
    position: absolute;
    top: 0;
    right: .5rem;
    cursor: pointer;
    font-weight: 700;
  }
  >ul{
    *{
      margin-top: .25rem;
      margin-bottom: .25rem;
    }
  }

`

const TestPageHeader = () => {
  const history = useHistory();
  return (
    <StyledTestHeader>
      {/* <span>
        {Title}
      </span> */}
      <Button
        onClick={() =>
          history.push({
            pathname: `${ROUTES.TAKE.replace(":cat", String(0))
              .replace(":rand", String(1))
              .replace(":exist", String(0))
              .replace(":chap", String(0))
              }`,
          })
        }
      >
        Take Full Exam
      </Button>
    </StyledTestHeader>
  )
}

const InfoBox = () => {
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false)
  let content
  if (showInfoBox) {
    content = (
      <StyledInfoBox>
        <div>Review Tests are now Available!</div>
        <div onClick={() => setShowInfoBox(false)}>x</div>
        <ul>
          <li>Historical chart aggregates the past five exams for the specific category.</li>
          <li>Clicking on on (red, green or blue) part of the circular chart to take an exam with only those specific questions, (incorrect, correct, unanswered)</li>
          <li>Last Exam Chart represents in-progress exam or last previously taken exam click on it to continue or review it.</li>
          <li>Reset historical exam history to clear all tests for a category.</li>
          <li>"Start Chapter Exam" starts a fresh chapter exam.</li>
        </ul>
      </StyledInfoBox>
    )
  } else {
    content = (
      <StyledInfoBox closed={true}>
        <div onClick={() => setShowInfoBox(true)}>Click here for information on review tests!</div>
      </StyledInfoBox>
    )
  }
  return content
}

export const Tests = () => {
  const { moduleTestMeta } = useContext<StoreConextInterface>(StoreContext);
  const { categories } = moduleTestMeta;

  const includeTestHistorySidebar = false
  let testHistoryComponent = <div />
  if (includeTestHistorySidebar) {
    testHistoryComponent = <TestHistory />
  }

  return (
    <StyledTest>
      <InfoBox />
      <TestPageHeader></TestPageHeader>
      <div>
        <div>
          {categories.map(c => (
            // <TestCategory key={c.id} category={c} />
            <NewTestCategory key={c.id} category={c} />
          ))}
        </div>
        {testHistoryComponent}
      </div>
    </StyledTest>
  );
};

interface TestCategoryProps {
  category: Category;
}

interface CategoryTestHeaderButtonsProps {
  id: number;
  categoryName: string
}

const StyledCategoryTestHeaderButtons = styled.div`
  font-size: ${smallText};
  min-width: 50%;
  display: flex;
  justify-content: space-evenly;
  background-color: ${secondaryBackgroundColor};
  padding-bottom: 10px;
  // margin: 0.5rem 0;
  > button {
    margin-top: 1rem;
    &:first-of-type {
      margin-right: 0.25rem;
    }
  }
`;

// TODO: double check component name
export const CategoryTestFooterButtons = ({ id, categoryName }: CategoryTestHeaderButtonsProps) => {
  const history = useHistory();

  return (
    <StyledCategoryTestHeaderButtons>
      <Button
        onClick={() =>
          history.push({
            pathname: `${ROUTES.TAKE.replace(":cat", String(id))
              .replace(":rand", String(1))
              .replace(":exist", String(0))
              .replace(":chap", String(0))
              }`,
          })
        }
      >
        Take {categoryName} Exam
      </Button>
    </StyledCategoryTestHeaderButtons>
  );
};
