import {
  ActionType,
  Action,
  UserContextInterface,
  UserModuleTestInfo,
  UserInitLoadAction,
  UserAddTestResultAction,
  UserTest,
  UserDeleteTestResultAction,
  SeenVideo,
  UserAddVideoResultAction,
  UserDeleteVideoResultAction,
  UserDeleteTestsResultAction,
} from "../types";
import { Reducer, useReducer, useContext, useState, useEffect } from "react";
import React from "react";
import { FirebaseContext } from "./firebaseContext";
import { MAX_USER_TESTS_ALLOWED } from "../constants";
import { trimTestCollection, deepClone } from "../helpers/structures";

export const UserContext = React.createContext<UserContextInterface>({
  userModuleTestInfo: {},
  getUserTestData: () => {},
  clearTestResult: () => {},
  clearTestResults: () => {},
  addUserTest: () => {},
  addUserVideo: () => {},
  removeUserVideo: () => {},
});

const defaultState = {
  MODULE1: { tests: [], videos: [] },
  MODULE2: { tests: [], videos: [] },
  MODULE3: { tests: [], videos: [] },
  MODULE4: { tests: [], videos: [] },
};
const userReducer: Reducer<UserModuleTestInfo, Action> = (state: UserModuleTestInfo, action): UserModuleTestInfo => {
  switch (action.type) {
    case ActionType.init: {
      const initLoad = action as UserInitLoadAction;
      return {
        ...state,
        ...initLoad.payload.userModuleTestInfo,
      };
    }
    case ActionType.addTest:
      let ts: UserTest[] = [];
      const addUserTest = action as UserAddTestResultAction;
      const moduleTests = state[addUserTest.payload.moduleName];
      if (moduleTests) {
        ts = [
          ...moduleTests.tests.filter(t => t.timestamp !== addUserTest.payload.test.timestamp),
          addUserTest.payload.test,
        ];
      }
      return {
        ...state,
        [addUserTest.payload.moduleName]: {
          tests: trimTestCollection(ts, MAX_USER_TESTS_ALLOWED),
          videos: moduleTests && moduleTests.videos,
        },
      };
    case ActionType.addVideo:
      let sv: SeenVideo[] = [];
      const addSeenVideos = action as UserAddVideoResultAction;
      const moduleVideos = state[addSeenVideos.payload.moduleName];
      if (moduleVideos) {
        sv = [...moduleVideos.videos.filter(v => v.id !== addSeenVideos.payload.video.id), addSeenVideos.payload.video];
      }
      return {
        ...state,
        [addSeenVideos.payload.moduleName]: { tests: moduleVideos.tests, videos: sv },
      };
    case ActionType.deleteTest:
      const removeUserTest = action as UserDeleteTestResultAction;
      const mod = state[removeUserTest.payload.moduleName];
      const tests = mod.tests.filter(t => t.timestamp !== removeUserTest.payload.timestamp);
      return {
        ...state,
        [removeUserTest.payload.moduleName]: { tests, videos: mod.videos },
      };
    case ActionType.deleteTests:
      const removeUserTests = action as UserDeleteTestsResultAction;
      const module = state[removeUserTests.payload.moduleName];
      const modifiedTests = module.tests.filter(t => !removeUserTests.payload.timestamps.includes(t.timestamp));
      return {
        ...state,
        [removeUserTests.payload.moduleName]: { tests: modifiedTests, videos: module.videos },
      };
    case ActionType.deleteVideo:
      const removeUserVideo = action as UserDeleteVideoResultAction;
      const deleteVideoState = state[removeUserVideo.payload.moduleName];
      const video = deleteVideoState.videos.filter(t => t.id !== removeUserVideo.payload.id);
      return {
        ...state,
        [removeUserVideo.payload.moduleName]: { tests: deleteVideoState.tests, videos: video },
      };
    default:
      throw new Error();
  }
};

interface UserProviderProps {
  children: React.ReactNode;
}

function UserProvider(props: UserProviderProps) {
  const { firebase } = useContext(FirebaseContext);
  const [userId, setUserId] = useState<string>("");
  const [storedUserData, setStoredUserData] = useState<UserModuleTestInfo>();
  const [userModuleTestInfo, userDispatch] = useReducer(userReducer, defaultState);

  const getUserTestData = (userId: string, callback: Function) => {
    setUserId(userId);
    try {
      firebase.doRetrieveUserData(userId, (data: object) => {
        const userModuleTestInfo = data as UserModuleTestInfo;
        const action = { type: ActionType.init, payload: { userModuleTestInfo } };
        userDispatch(action);
        setStoredUserData(deepClone<UserModuleTestInfo>(userModuleTestInfo));
      });
    } catch (e) {
      callback(`User's data could not be retrieved, ${e}`);
    }
  };

  const addUserTest = (moduleName: string, test: UserTest) => {
    const addUserTest: UserAddTestResultAction = { type: ActionType.addTest, payload: { moduleName, test } };
    userDispatch(addUserTest);
  };
  const clearTestResult = (moduleName: string, timestamp: number) => {
    const removealData: UserDeleteTestResultAction = {
      type: ActionType.deleteTest,
      payload: { moduleName, timestamp },
    };
    userDispatch(removealData);
  };
  const clearTestResults = (moduleName: string, timestamps: number[]) => {
    const removealData: UserDeleteTestsResultAction = {
      type: ActionType.deleteTests,
      payload: { moduleName, timestamps },
    };
    userDispatch(removealData);
  };
  const addUserVideo = (moduleName: string, video: SeenVideo) => {
    const addUserVideo: UserAddVideoResultAction = { type: ActionType.addVideo, payload: { moduleName, video } };
    userDispatch(addUserVideo);
  };
  const removeUserVideo = (moduleName: string, id: string) => {
    const removealData: UserDeleteVideoResultAction = { type: ActionType.deleteTest, payload: { moduleName, id } };
    userDispatch(removealData);
  };

  useEffect(() => {
    if (JSON.stringify(defaultState) === JSON.stringify(userModuleTestInfo)) return;

    const storeUserData = () => {
      firebase.doStoreUserData(userId, userModuleTestInfo, () =>
        setStoredUserData(deepClone<UserModuleTestInfo>(userModuleTestInfo)),
      );
    };

    if (userId && JSON.stringify(storedUserData) !== JSON.stringify(userModuleTestInfo)) {
      storeUserData();
    }
  }, [userId, storedUserData, userModuleTestInfo, firebase]);

  const userItems = {
    userModuleTestInfo,
    getUserTestData,
    clearTestResult,
    clearTestResults,
    addUserTest,
    addUserVideo,
    removeUserVideo,
  };

  return <UserContext.Provider value={userItems}>{props.children}</UserContext.Provider>;
}

export default UserProvider;
