import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { EXAM_TYPES, ROUTES } from '../constants';
import { StoreContext } from '../contexts/storeContext';
import { StoreConextInterface, UserTest } from '../types';
import { smallText, secondaryBackgroundColor, largeText, Button, regBorderRadius, almostWhite } from "./common";

const StyledTestHistory = styled.div`
  font-size: ${smallText};
  display: flex;
  border-radius: ${regBorderRadius};
  flex-direction: column;
  justify-content: flex-start;
  min-width: 35%;
  max-height: calc(100vh - 190px);
  background-color: ${almostWhite};
  border-bottom: ${secondaryBackgroundColor} 1px solid;
  border-left: ${secondaryBackgroundColor} 1px solid;
  border-right: ${secondaryBackgroundColor} 1px solid;
  height: min-content;
  margin-top: 10px;

  > span:first-of-type {
    font-size: ${largeText};
    background-color: ${secondaryBackgroundColor};
    border-top-left-radius: ${regBorderRadius};
    border-top-right-radius: ${regBorderRadius};
    width: calc(100% - 15px);
    display: inline-block;
    padding: 10px 0 10px 15px;
  }

  > div:nth-of-type(1) {
    > select {
      width: 100%
    }
  }

  > div:nth-of-type(2) {
    min-height: ${(props: TestHistoryProps) =>
    props.chapter ? 'auto' : '250px'
  }
    max-height: ${(props: TestHistoryProps) =>
    props.chapter ? '300px' : '600px'
  }
    overflow-y: scroll; 

    > table {
      border-collapse: collapse;
      width:100%;
      padding: 0.25rem;
      max-height: 100%;
      background-color: ${secondaryBackgroundColor};
      text-align: center;
  
      tbody{
        background-color: ${almostWhite};
        overflow-y: scroll;
        min-height: ${(props: TestHistoryProps) =>
    props.chapter ? 'inherit' : '426px'
  }
        button{
          width: auto;
        }
        tr{
          td:nth-child(2){
            font-size: 24px;
          }
        }
      }
    }
  }
`;

const StyledTestResult = styled.tr`
  padding: 0 0.25rem;

  > span:first-of-type {
    font-size: ${largeText};
  }

  > button:first-of-type {
    width: 75px;
  }

  > button:nth-of-type(2) {
    padding: 0.25em;
    width: 25px;
  }
`;

const getHistoryType = (testResult: UserTest): string => {
  switch (testResult.type) {
    case EXAM_TYPES.RAND_CAT:
      return `Randomized Cat. ${testResult.categoryId} Exam`;
    case EXAM_TYPES.RAND_EXAM:
      return "Randomized Exam";
    case EXAM_TYPES.REG_CAT:
      return `Full Cat. ${testResult.categoryId} Exam`;
    case EXAM_TYPES.REG_EXAM:
      return "Full Exam";
    case EXAM_TYPES.REG_CHP:
      return `Full Chap. ${testResult.chapterId} Exam`;
    case EXAM_TYPES.MISS_REVIEW:
      return `Chap. ${testResult.chapterId} Missed Review`;
    case EXAM_TYPES.SKIP_REVIEW:
      return `Chap. ${testResult.chapterId} Skipped Review`;
    case EXAM_TYPES.CORR_REVIEW:
      return `Chap. ${testResult.chapterId} Correct Review`;
    default:
      return "Full Exam";
  }
};

let lastModule: string | null = null

interface TestHistoryProps {
  chapter?: number
}

const TestHistoryTableContent = (filterChapter?: number) => {
  const history = useHistory();
  const { userTestData, removeTestResult } = useContext<StoreConextInterface>(StoreContext);

  let filteredHistory = userTestData
  if (filterChapter) {
    filteredHistory = filteredHistory.filter(x => x.chapterId === filterChapter)
  }

  let filteredHistoryHtml
  if (filteredHistory.length > 0) {
    filteredHistoryHtml = filteredHistory
      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
      .map(testResult => (
        <StyledTestResult key={testResult.timestamp}>
          <td>
            <Button
              onClick={() =>
                history.push({
                  pathname: `${ROUTES.TAKE.replace(":cat", String(0))
                    .replace(":rand", String(0))
                    .replace(":exist", String(testResult.timestamp))
                    .replace(":chap", String(0))
                    }`,
                })
              }
            >Review
          </Button>
          </td>
          <td>
            {testResult.correctPct}%
        </td>
          <td>{getHistoryType(testResult)}</td>
          <td>{testResult.questionsAnswered} / {testResult.questionsTotal}</td>
          <td>
            <Button secondary onClick={() => removeTestResult(testResult.timestamp)}>
              X
      </Button>
          </td>
        </StyledTestResult>
      ))
  } else {
    filteredHistoryHtml = <tr><td colSpan={5}>No Test History</td></tr>
  }

  return filteredHistoryHtml
}

export const TestHistory = (testHistoryProps: TestHistoryProps) => {

  const { activeModule, moduleTestMeta } = useContext<StoreConextInterface>(StoreContext);
  const categories = moduleTestMeta.categories
  const [filterChapter, setFilterChapter] = useState<number | undefined>(undefined);

  // resets the filtered chapter when entering test page
  useEffect(() => {
    setFilterChapter(undefined)
  }, [setFilterChapter])

  // TODO: look for a proper way of resetting filterChapter when changing module
  //reset filtered chapter when changing module
  if (activeModule !== lastModule) {
    lastModule = activeModule
    setFilterChapter(undefined)
  }

  if (testHistoryProps.chapter !== undefined && testHistoryProps.chapter !== filterChapter) {
    setFilterChapter(testHistoryProps.chapter)
  }

  return (
    <StyledTestHistory chapter={testHistoryProps.chapter}>
      {testHistoryProps.chapter === undefined && <span>Test Result History</span>}
      <div>
        {testHistoryProps.chapter === undefined &&
          <select key={`select_key__${filterChapter}`} value={filterChapter || ''} onChange={e => setFilterChapter(parseInt(e.target.value))} name="chapter" id="x">
            <option key={0}>All</option>
            {categories.map(category => (
              <optgroup key={category.id} label={`Cat. ${category.id} ${category.name}`}>
                {category.chapters.map(chapter => (
                  <option key={chapter.id} value={chapter.id}>Ch. {chapter.id} {chapter.name}</option>
                ))}
              </optgroup>
            ))}
          </select>
        }
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>% Correct</th>
              <th>Type</th>
              <th>Questions Answered</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {TestHistoryTableContent(filterChapter)}
          </tbody>
        </table>
      </div>
    </StyledTestHistory>
  );
};