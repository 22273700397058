import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { PASSABLE_LIMIT } from "../constants";
// Nebulas Blue
export const primarytextColor = "#ffffff"; // all white
export const topBarHeaderColor = "#3e5489";
export const almostWhite = "#f3f6fb"; // almost white
export const hoverColor = "#3e5489"; // next lightest
const text2Color = "#afc3e1";
export const altHoverColor = "#86a5d2";
const buttonColor = "#5e87c4"; // light blue
const mainColor = "#3f69aa";
//export const altHoverColor = "#39609a";
//const border2Color = "#335589";
//const border3Color = "#2c4b78";
//const border4Color = "#264067";
export const borderColor = "#203556"; // dark blue
export const inverseText = "#000000";
export const highlight = "#f0e277"; // highlight from Meadowlark
//const highlightDarket = "#e6ce1b"; // darker highlight from Meadowlark
export const fadedColor = "#b9b9b9";

export const tinyText = "0.25rem"
export const smallText = "0.75rem";
export const regText = "1rem";
export const largeText = "1.5rem";
export const veryLargeText = "3.5rem";

export const regBorderRadius = '10px'

export const screenSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const deviceSize = {
  mobileS: `(max-width: ${screenSize.mobileS})`,
  mobileM: `(max-width: ${screenSize.mobileM})`,
  mobileL: `(max-width: ${screenSize.mobileL})`,
  tablet: `(min-width: ${screenSize.mobileS}) and (max-width: ${screenSize.tablet})`,
  laptop: `(min-width: ${screenSize.tablet}) and (max-width: ${screenSize.laptop})`,
  laptopL: `(min-width: ${screenSize.tablet}) and (max-width: ${screenSize.laptopL})`,
  desktop: `(min-width: ${screenSize.laptop}) and (max-width: ${screenSize.desktop})`,
};

export const PROGRESS_STATUS = {
  SUCCESS: "#bed39b",
  FAILURES: "#f29189",
  ITEMSLEFT: text2Color,
};

export const backgroundColor = mainColor;
export const secondaryBackgroundColor = '#d7e1f0';

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
  margin-bottom: 5rem;
  
  > span:first-of-type {
    font-size: 45px;
    font-weight: 500;

    @media ${deviceSize.tablet} {
      font-size: ${largeText};
    }
  }
  > span:last-of-type {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6em;
    color: ${fadedColor};

    @media ${deviceSize.mobileL} {
      font-size: ${smallText};
    }
  }
`;

export const AppTitle = () => (
  <StyledTitle>
    <span>Texas Insurance License Exam Prep Course</span>
    <span>Your Key to Becoming Licensed in Texas</span>
  </StyledTitle>
);

interface ButtonProps {
  secondary?: boolean;
  selected?: boolean;
  disabled?: boolean;
  noHover?: boolean;
}

export const StyledHeaderSection = styled.div`
  background: ${topBarHeaderColor};
  min-height: 8px;
  width: 100%;
`;

export const StyledNavigationSection = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 3px;
  padding: 0.25em 1em;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 3px 5px 5px 0px rgba(35, 100, 210, 0.3);
  margin: .25rem;
  transition: background-color .17s ease,color .17s ease;
  
  cursor: ${(props: ButtonProps) =>
    props.selected || props.disabled ? 'default' : 'pointer'
  }

  color: ${(props: ButtonProps) =>
    props.disabled ? '#979faa' : almostWhite
  }

  background-color: ${(props: ButtonProps) =>
    props.selected ? hoverColor : 
      props.disabled ? secondaryBackgroundColor : buttonColor
  }

  @media ${deviceSize.desktop} {
    &:hover {
      background-color: ${(props: ButtonProps) =>
        props.secondary
          ? props.noHover || props.disabled
            ? hoverColor
            : buttonColor
          : props.noHover || props.disabled
            ? secondaryBackgroundColor
            : hoverColor};
        }
`

export const ButtonOld = styled.button`
  background: ${buttonColor};
  border-radius: 3px;
  border: 2px solid ${borderColor};
  color: ${almostWhite};
  outline: none;
  padding: 0.25em 1em;
  width: 100%;
  cursor: pointer;

  @media ${deviceSize.desktop} {
    &:hover {
      background: ${(props: ButtonProps) =>
        props.secondary
          ? props.noHover || props.disabled
            ? hoverColor
            : buttonColor
          : props.noHover || props.disabled
            ? buttonColor
            : hoverColor};
      color: ${(props: ButtonProps) =>
        props.secondary
          ? props.noHover || props.disabled
            ? inverseText
            : almostWhite
          : props.noHover || props.disabled
            ? almostWhite
            : inverseText};
    }
  }

  &:disabled {
    opacity: 50%;
  }

  ${(props: ButtonProps) =>
    props.secondary &&
    css`
      background: ${secondaryBackgroundColor};
      border: 2px solid ${borderColor};
      color: ${inverseText};
    `};

  ${(props: ButtonProps) =>
    props.selected &&
    css`
      background: ${highlight};
      color: ${inverseText};
    `};
`;

export const RoundButton = styled.button`
  background: ${primarytextColor};
  border-radius: 10px;
  color: ${inverseText};
  border: 1px solid ${borderColor};
  width: 50px;
  outline: none;
  cursor: pointer;

  ${(props: ButtonProps) =>
    props.selected &&
    css`
      background: ${highlight};
    `};

  @media ${deviceSize.desktop} {
    &:hover {
      background: ${highlight};
    }
  }
`;

const StyledNavLink = styled.span`
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: ${smallText};

  > a {
    color: ${inverseText};
    font-size: ${smallText};
    font-weight: 400;
    text-transform: uppercase;
  }

  a:link {
    text-decoration: none;
  }

  ${(props: NavLinkStyleProps) =>
    props.active &&
    css`
      border-bottom: 4px solid ${hoverColor};
    `};
`;

interface NavLinkStyleProps {
  active?: boolean;
}
interface NavLinkProps {
  page: string;
  text: string;
  active?: boolean;
  disabled?: boolean;
}

export const AppNavLink = (props: NavLinkProps) => (
  <StyledNavLink active={props.active}>
    {!props.disabled ? (
      <NavLink exact to={props.page}>
        {props.text}
      </NavLink>
    ) : (
      props.text
    )}
  </StyledNavLink>
);

interface SelectProps {
  defaultValue: string;
  onChange: Function;
  options: string[];
}

const StyledSelect = styled.select`
  background: ${almostWhite};
  border-radius: 3px;
  border: 2px solid ${borderColor};
  color: ${inverseText};
  width: 100%;
  height: 35px;
  font-size: 14px;
`;

export const SelectDropdown = (props: SelectProps) => {
  const [selected, setSelected] = useState<string>(props.defaultValue);
  return (
    <StyledSelect
      onChange={(e: any) => {
        const selected = e.target.options[e.target.selectedIndex].label;
        setSelected(selected);
        props.onChange(selected);
      }}
      value={selected}
    >
      {props.options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
};

export interface ProgressProps {
  pct: number;
  extraPadding?: boolean;
  smallFont?: boolean;
}

export const StyledProgress = styled.span`
  ${(props: ProgressProps) =>
    css`
      padding: 0.25rem;
      display: inline-block;
      border-radius: 2px;
      background: ${props.pct >= PASSABLE_LIMIT ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.FAILURES};
      border: 1px solid ${borderColor};
      color: ${inverseText};
      font-size: ${props.smallFont ? smallText : regText};
      text-align: center;
    `};
`;

interface StyledAppErrorProps {
  isInfo: boolean;
}

export const StyledAppMessage = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  
  ${(props: StyledAppErrorProps) => css`
  > button {
    position: absolute;
    right: 20px;
    background: ${props.isInfo ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.FAILURES};
    border: none;
    align-self: flex-end;
    width: 30px;
    color: ${inverseText};
  }
  `};
  ${(props: StyledAppErrorProps) =>
    css`
      background: ${props.isInfo ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.FAILURES};
      color: ${inverseText};
      font-size: ${regText};
      text-align: center;
    `};
`;

const StyledTimeElapsed = styled.span`
  font-size: ${smallText};
`;
interface TimeElpasedProps {
  elapsed: number;
}
export const TimeElapsed = ({ elapsed }: TimeElpasedProps) => {
  const hours = Math.floor((elapsed / 3600) % 24);
  const minutes = Math.floor((elapsed / 60) % 60);
  const seconds = Math.floor(elapsed % 60);
  const elapsedFormatted = `${hours} Hrs, ${minutes} Min, ${seconds} Sec`;

  return <StyledTimeElapsed>{elapsedFormatted}</StyledTimeElapsed>;
};
