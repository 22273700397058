import React from "react"
import { Doughnut } from 'react-chartjs-2';
import styled from "styled-components";

interface ChartJsProps {
  data: number[],
  labels: string[],
  insideText?: number,
  insideTextUnit?: string,
  handleEvent?: Function
  chartLabel?: string
}

const StyledChartJsDoughnut = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  position: relative;

  > div:nth-of-type(2) {
    position: absolute;
    width: 100%;
    text-align: center;
    top: ${(props: any) =>
    props.chartLabel !== undefined ? 'calc(50% - 26px)' : 'auto'
  };
    
    >div{
      font-size:12px;
    }
  }

  >div:nth-of-type(3){
    margin-top: 2px;
    width:100%;
    text-align: center;
  }

  canvas {
    z-index: 1;
  }
`

export const ChartJsDoughnutComponent = (props: ChartJsProps) => {
  let options: any = {
    legend: {
      display: false
    },
    cutoutPercentage: 66,
    maintainAspectRatio: true,
    tooltips: {
      enabled: true
    },
    responsive: true
  }

  if (props.handleEvent) {
    options.onClick = props.handleEvent
    options.onHover = (event: any, chartElement: any) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  }


  const fillAlpha = '33'
  const colors = {
    correct: '#00e500',
    incorrect: '#ff0040',
    notAnswered: '#0073ff'
  }

  const chartData = {
    labels: props.labels,
    datasets: [{
      label: '',
      data: props.data,
      backgroundColor: [
        colors.correct + fillAlpha,
        colors.incorrect + fillAlpha,
        colors.notAnswered + fillAlpha
      ],
      borderColor: [
        colors.correct,
        colors.incorrect,
        colors.notAnswered
      ],
      borderWidth: 1
    }],

  }

  let insideTextHtml
  if (props.insideText || props.insideText === 0) {
    insideTextHtml = (
      <div>
        {props.insideText}%
        {props.insideTextUnit && <div>{props.insideTextUnit}</div>}
      </div>
    )
  }

  return (
    <StyledChartJsDoughnut
      chartLabel={props.chartLabel}
    >
      <Doughnut
        data={chartData}
        options={options}
      // width={165}
      // height={100}
      />
      {insideTextHtml && insideTextHtml}
      {props.chartLabel && <div>{props.chartLabel}</div>}
    </StyledChartJsDoughnut>
  )
}
