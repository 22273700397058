import React, { useContext, useState, useEffect } from "react";
import { Flashcard, StoreConextInterface } from "../types";
import styled from "styled-components";
import { SelectDropdown, Button, hoverColor, deviceSize, largeText } from "./common";
import { StoreContext } from "../contexts/storeContext";
import { Redirect } from 'react-router'
import { ROUTES, TEST_ONLY } from "../constants";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50%;
`;

const StyledFlashcardsSection = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 56px;
  padding: 1rem 0;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  font-weight: 300;
  line-height: 1.1;
  flex: 1;

  @media ${deviceSize.mobileL} {
    font-size: ${largeText};
  }

  > div:first-of-type {
    align-content: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    border-bottom: 2px solid ${hoverColor};
    margin-bottom: 0.5rem;
  }
`;

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  width: 80%;

  @media ${deviceSize.desktop} {
    > select,
    > button {
      width: 100px;
    }
  }
`;

const ALL = "All";
export const Flashcards = () => {
  const { moduleTestFlashcards, activeModule, moduleAccess } = useContext<StoreConextInterface>(StoreContext);
  const [flashcards, setFlashcards] = useState<Flashcard[]>(moduleTestFlashcards && moduleTestFlashcards.flashcards);
  const [topics] = useState<string[]>([
    ALL,
    ...Array.from(new Set(moduleTestFlashcards.flashcards.map(f => f.t).sort())),
  ]);
  const [index, setIndex] = useState<number>(0);
  const [topic, setTopic] = useState<string>(ALL);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const updateIndex = (newIndex: number) => {
    setIndex(newIndex);
    setShowAnswer(false);
  };
  
  useEffect(() => {
    setShowAnswer(false);
    setFlashcards(
      topic === ALL ? moduleTestFlashcards.flashcards : moduleTestFlashcards.flashcards.filter(f => f.t === topic),
    );
    setIndex(0);
  }, [topic, moduleTestFlashcards.flashcards]);
  let a = "";
  let q = "";
  if (flashcards[index]) {
    a = flashcards[index].a;
    q = flashcards[index].q;
  }
  
  if (activeModule && moduleAccess[activeModule] === TEST_ONLY) return <Redirect to={ROUTES.SIGN_IN} />;

  return (
    <StyledSection>
      <StyledFlashcardsSection>
        <div><b>{q}</b></div>
        {showAnswer && <div>{a}</div>}
      </StyledFlashcardsSection>
      <StyledButtonBar>
        <SelectDropdown defaultValue={ALL} options={topics} onChange={(value: string) => setTopic(value)} />
        <Button disabled={index === 0} onClick={() => (index > 0 ? updateIndex(index - 1) : null)}>
          Back
        </Button>
        <Button onClick={() => setShowAnswer(!showAnswer)}>{showAnswer ? `Hide Answer` : `Show Answer`}</Button>
        <Button
          disabled={index === flashcards.length - 1}
          onClick={() => (index < flashcards.length - 1 ? updateIndex(index + 1) : null)}
        >
          Next
        </Button>
      </StyledButtonBar>
    </StyledSection>
  );
};
