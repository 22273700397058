import { MODULE1, MODULE2, MODULE3, MODULE4, MODULE5 } from "./constants";
import { ModuleStudyGuides, ModuleVideo } from "./types";

export const VIDEOS: ModuleVideo = {
  [MODULE1]: [
    { chapter: "1", link: "https://youtu.be/DhdWR2esg3o" },
    { chapter: "2", link: "https://youtu.be/dXSovdl5Ypk" },
    { chapter: "3", link: "https://youtu.be/U1bIyim48c8" },
    { chapter: "4", link: "https://youtu.be/sjcTbhVT0K0" },
    { chapter: "5", link: "https://youtu.be/pHBN4yM5lzc" },
    { chapter: "6", link: "https://youtu.be/iDRcrCYO8Cw" },
    { chapter: "7", link: "https://youtu.be/BnAPvDHFVr4" },
    { chapter: "8", link: "https://youtu.be/VrNQdh7o09g" },
    { chapter: "9", link: "https://youtu.be/8bsHKmvXu5I" },
    { chapter: "10 PART I", link: "https://youtu.be/BQ95SxPELe8" },
    { chapter: "10 PART II", link: "https://youtu.be/a90tCb1Ai9E" },
    { chapter: "11", link: "https://youtu.be/CPs6ozdjmIs" },
    { chapter: "12", link: "https://youtu.be/EXAnHM0U9EA" },
    { chapter: "13", link: "https://youtu.be/HwsXHv7XSdc" },
  ],
  [MODULE2]: [
    { chapter: "1", link: "https://youtu.be/zYu1qsrORaU" },
    { chapter: "2", link: "https://youtu.be/dXSovdl5Ypk" },
    { chapter: "3", link: "https://youtu.be/U1bIyim48c8" },
    { chapter: "4", link: "https://youtu.be/sjcTbhVT0K0" },
    { chapter: "5", link: "https://youtu.be/6kvytj0o0rY" },
    { chapter: "6 Part I", link: "https://youtu.be/0qq1rS43tJk" },
    { chapter: "6 Part II", link: "https://youtu.be/mdPJ5ThZShM" },
    { chapter: "7", link: "https://youtu.be/xqCvSrVhaNk" },
  ],
  [MODULE3]: [
    { chapter: "1 Part I", link: "https://youtu.be/nj9JejJMQII" },
    { chapter: "1 Part II", link: "https://youtu.be/MddabqUoH7o" },
    { chapter: "2", link: "https://youtu.be/9z6HOyozd5E" },
    { chapter: "3", link: "https://youtu.be/V_zj05c7Y-k" },
    { chapter: "4", link: "https://youtu.be/btguZGlZIlE" },
    { chapter: "5", link: "https://youtu.be/I7UGmy4yKOw" },
    { chapter: "6", link: "https://youtu.be/bRcn-VI1bsM" },
    { chapter: "7", link: "https://youtu.be/bU38kZfvHBM" },
    { chapter: "8", link: "https://youtu.be/Rd7a248g9KU" },
  ],
  [MODULE4]: [
    { chapter: "1", link: "https://youtu.be/dCMz4G-1cqE" },
    { chapter: "2", link: "https://youtu.be/qXARbE40MuA" },
    { chapter: "3", link: "https://youtu.be/StaP3JscJmU" },
    { chapter: "4", link: "https://youtu.be/VEVi9CqfWjY" },
    { chapter: "5", link: "https://youtu.be/I7UGmy4yKOw" },
    { chapter: "6", link: "https://youtu.be/bRcn-VI1bsM" },
    { chapter: "7", link: "https://youtu.be/bU38kZfvHBM" },
    { chapter: "8", link: "https://youtu.be/Rd7a248g9KU" },
  ],
  [MODULE5]: [],
};



export const STUDY_GUIDES: ModuleStudyGuides = {
  [MODULE1]: [
    { name: "Ch 1 - 4", source: "LifeAccidentHealth_Ch_1-4.pdf" },
    { name: "Ch 5 - 9", source: "LifeAccidentHealthCh_5-9.pdf" },
    { name: "Ch 10 - 13", source: "LifeAccidentHealth_Ch_10-13.pdf" },
    
  ],
  [MODULE2]: [
    { name: "Ch 1 - 5", source: "LifeAgent_Ch_1-5.pdf" },
    { name: "Ch 6 - 7", source: "LifeAgent_Ch_6-7.pdf" },
    
  ],
  [MODULE3]: [
    { name: "Ch 1 - 4", source: "GeneralLines_PC_Ch_1-4.pdf" },
    { name: "Ch 5 - 8", source: "GeneralLines_PC_Ch_5-8.pdf" },
    
  ],
  [MODULE4]: [
    { name: "Ch 1 - 4", source: "PersonalLines_Ch_1-4.pdf" },
    { name: "Ch 5 - 8", source: "PersonalLines_Ch_5-8.pdf" },
    
  ],
  [MODULE5]: [],
};
