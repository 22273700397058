import React, { useState, useContext } from "react";
import useErrorHandler from "../hooks/error-handler";
import { validateLoginForm } from "../helpers/validators";
import { StoreContext } from "../contexts/storeContext";
import { Button, smallText, inverseText, deviceSize, hoverColor, secondaryBackgroundColor } from "./common";
import * as validator from "validator";
import styled from "styled-components";

export const StyledWelcome = styled.div`
  align-items: center;
  display: flex;

  > span {
    color: ${inverseText};
    font-size: ${smallText};
  }

  > button {
    margin: 0rem 0.5rem;
    width: unset;
  }
  @media ${deviceSize.mobileL} {
    align-self: flex-end;
  }
`;

export const Welcome = () => {
  const { loggedInUser, logoutUser } = useContext(StoreContext);

  if (!loggedInUser) return null;
  return (
    <StyledWelcome>
      <span>Welcome, {loggedInUser && loggedInUser.user && loggedInUser.user.email}</span>
      <Button
        onClick={(e: any) => {
          e.preventDefault();
          logoutUser();
        }}
      >
        Logout
      </Button>
    </StyledWelcome>
  );
};

const StyledLoginForm = styled.div`

  flex-direction: column !important;

  >div:first-of-type {

    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: ${hoverColor}
    background:${secondaryBackgroundColor};
    padding: 32px;
    font-weight: bold;
    font-size: 18px;
    box-shadow: 0 2px 10px 0 ${secondaryBackgroundColor};
    border-radius: 5px;
    box-sizing: border-box;
    max-width: 480px;
    height: min-content;

    @media ${deviceSize.tablet} {
      max-width: inherit;
    }
    
    > input {
      height: 25px;
      padding: 0 0.5rem;
      margin: 0.5rem 0;
    }
    
    > button:first-of-type {
      font-size: ${smallText};
      margin-bottom: 0.25rem;
      background: none;
      border: none;
      text-align: left;
      padding: 0;
      cursor: pointer;
      color: ${hoverColor};
      
      &:hover {
        text-decoration: underline;
      }
    }
    > button:nth-of-type(2) {
      height: 42px;
      margin: .5rem 0 0 0;
    }
  }

`;

export function Login() {
  const { loggedInUser, loginUser, loginError, sendPasswordResetEmail } = useContext(StoreContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { error, showError } = useErrorHandler(null);

  if (loggedInUser) return null;
  return (
    <StyledLoginForm>
      <div>
        Login to Start Learning:
        <input
          name="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={!validator.isEmail(email)} onClick={() => sendPasswordResetEmail(email)}><b>Click here to</b> / <b> reset or set up new password</b> An email will be sent (Check your spam folder)</button>
        <input
          name="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
        />
        <Button
          onClick={(e: any) => {
            e.preventDefault();
            if (validateLoginForm(email, password, showError)) {
              loginUser(email, password);
            }
          }}
        >
          Login
        </Button>
        <span>{error || loginError}</span>
      </div>
    </StyledLoginForm>
  );
}
