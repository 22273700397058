import React, { useContext, useState } from "react";
import { Category, Chapter, StoreConextInterface, UserTest } from "../types";
import styled from "styled-components";
import { StoreContext } from "../contexts/storeContext";
import { almostWhite, Button, secondaryBackgroundColor } from "./common";
import { EXAM_TYPES, FULL_ACCESS, ROUTES } from "../constants";
import { useHistory } from "react-router-dom";
import { CategoryTestFooterButtons } from './tests'
import { ChapterStatsContainer } from "./test-chapter-stats";
import { ChartJsDoughnutComponent } from "./test-chartjs-doughnut";
import {ChapterHistoryChart} from './test-chapter-history-chart'

const StyledCategoryContainer = styled.div`
  border: 1px solid #d7e1f0
  border-radius: 10px;
  margin: 10px 5px 10px 0;
  padding: 0 4px;
  background-color: ${secondaryBackgroundColor}

  > span:first-of-type {
    font-size: 24px;
    background-color: #d7e1f0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: calc(100% - 15px);
    display: inline-block;
    padding: 10px 0 10px 15px;
  }
`

const StyledChapterContainer = styled.div`
  
  padding: 10px;
  
  &:nth-child(2n - 1){
    background-color: #fff;
  }

  &:nth-child(2n){
    background-color: ${almostWhite};
  }

  > div: nth-child(2){
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 90px;
    margin: 5px;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      button {
        height: min-content;
        margin: 0 .25rem;
        width: 150px;
      }
    }
  
    > div:nth-child(2) {
      display:flex;
      flex-direction: row;
      >div:nth-child(1){
        display:flex;
        flex-direction: row;
        > div{
          width: 165px;
          margin: auto 10px;
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        > button{
          width: max-content;
          height: min-content;
          margin: 0 .25rem;
        }
      }
    }

    @media (max-width: 1052px) {
      flex-direction: column;

      > div:nth-child(1) {
        flex-direction: row !important;
        > button{
          margin: 1rem .25rem;
          width: 130px;
          height: 40px;
        }
      }

      > div:nth-child(2) {
        flex-direction: column;
        >div:nth-child(1){
          justify-content: space-evenly;
        }
        >div:nth-child(2){
          flex-direction: row;
          justify-content: center;
          margin: 1rem .25rem 
        }
      }
    }
  }
`

interface ChapterContainerProps {
  chapter: Chapter;
  currentAnswer: UserTest
  includeCollapsable?: boolean
}

const goToLastTestAttempt = (history:any, timestamp: number) => {
  setTimeout(()=>{
    history.push({
      pathname: `${ROUTES.TAKE.replace(":cat", String(0))
        .replace(":rand", String(0))
        .replace(":exist", String(timestamp))
        .replace(":chap", String(0))
        }`,
    })
  })
}

const ChapterContainer = (props: ChapterContainerProps) => {
  const history = useHistory();
  const { activeModule, moduleAccess, removeTestResults, userTestData } = useContext<StoreConextInterface>(StoreContext);
  const [numberOfTestsConsidered, setNumberOfTestsConsidered] = useState<number>(5);

  const includeChapterStatsCollapsable = props.includeCollapsable

  const chapter = props.chapter
  const currentAnswer = props.currentAnswer

  const includeNotAnswered = true
  let chartData = [0, 0, 1]
  let chartLabels = ['Correct', 'Missed']
  if (currentAnswer !== undefined) {
    chartData = [currentAnswer.correctAnswers, currentAnswer.wrongAnswers]

    if (includeNotAnswered) {
      chartData.push(currentAnswer.questionsTotal - currentAnswer.correctAnswers - currentAnswer.wrongAnswers)
      chartLabels.push('Not Answered')
    }
  }

  let charts = (<></>)

  if (currentAnswer) {
    charts = (
      <div>
        <div>
          <ChartJsDoughnutComponent
            // TODO: revisit why userTestData.chapter starts at 1 and categories[x].id starts at 0
            data={chartData}
            labels={chartLabels}
            insideText={Math.round(currentAnswer.correctPct)}
            insideTextUnit={'Correct'}
            chartLabel={'Last Exam'}
            handleEvent={() => goToLastTestAttempt(history, currentAnswer.timestamp)}
          />
          <ChapterHistoryChart
            chapter={chapter.id}
            numberOfTestsConsidered={numberOfTestsConsidered}
            setNumberOfTestsConsidered={setNumberOfTestsConsidered}
            chartLabel={'Historical'}
          />
        </div>
        <div>
          <Button onClick={() =>
            removeTestResults(userTestData.filter(x => x.chapterId === chapter.id).map(x => x.timestamp))
          }>Clear History</Button>
        </div>
      </div>
    )
  }

  return (
    <StyledChapterContainer>
      <div>Ch. {chapter.id}, {chapter.name}</div>
      <div>
        <div>
          <Button
            onClick={() =>
              history.push({
                pathname: `${ROUTES.TAKE.replace(":cat", String(0))
                  .replace(":rand", String(0))
                  .replace(":exist", String(0))
                  .replace(":chap", String(chapter.id))
                  }`,
              })
            }
          >
            Start Chapter Exam
          </Button>
        </div>
        {charts}
      </div>
      {includeChapterStatsCollapsable && activeModule && moduleAccess[activeModule] === FULL_ACCESS && currentAnswer &&
        <ChapterStatsContainer chapter={chapter.id}></ChapterStatsContainer>
      }
    </StyledChapterContainer>
  )
}

interface TestCategoryProps {
  category: Category;
}

export const NewTestCategory = ({ category }: TestCategoryProps) => {
  const { userTestData } = useContext<StoreConextInterface>(StoreContext);

  return (
    <StyledCategoryContainer>
      <span>{category.name}</span>
      <div>
        {category.chapters.map((chapter: Chapter) => (
          <ChapterContainer
            key={category.id + chapter.id}
            chapter={chapter}
            currentAnswer={
              userTestData.filter(x => x.chapterId === chapter.id && x.type === EXAM_TYPES.REG_CHP)
                .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))[0]
            }
          />
        ))}
      </div>
      <CategoryTestFooterButtons id={category.id} categoryName={category.name} />
    </StyledCategoryContainer>
  );
};
