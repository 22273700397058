import React from "react";
import { largeText, veryLargeText, Button, smallText, hoverColor, regText } from "./common";
import { UserTest, UserQuestionAnswers } from "../types";
import styled from "styled-components";
import check from "../assets/check.png";
import wrong from "../assets/wrong.png";
import notAnswered from "../assets/notAnswered.png";


const StyledTakeTestSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface TakeTestSummaryProps {
  userTest: UserTest;
  gotoQuestion: Function;
}

export const TakeTestSummary = ({ userTest, gotoQuestion }: TakeTestSummaryProps) => (
  <StyledTakeTestSummary>
    <div>
      <ResultSummary userTest={userTest} />
      <TakeTestScore correctPct={userTest.correctPct} />
    </div>
    <TakeTestReview userQuestions={userTest.questions} gotoQuestion={gotoQuestion} />
  </StyledTakeTestSummary>
);
interface TakeTestReviewProps {
  userQuestions: UserQuestionAnswers[];
  gotoQuestion: Function;
}

const StyledTakeTestReview = styled.div`
  font-size: ${largeText};

  > div {
    align-items: center;
    font-size: ${smallText};
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${hoverColor};
    margin-top: 1rem;

    > button {
      width: 150px;
    }
  }
`;

const TakeTestReview = ({ userQuestions, gotoQuestion }: TakeTestReviewProps) => (
  <StyledTakeTestReview>
    Review
    {userQuestions.map((u, i) => (
      <div key={u.questionId+i}>
        <span>Category {u.categoryId}</span>
        <span>Chapter {u.chapterId}</span>
        {/* <span>Question: {u.questionId + 1}</span> */}
        <span>Question: {i + 1}</span>
        <span>{u.isCorrect ? <img alt="check" src={check} /> : u.answer === "" ? <img alt="notAnswered" src={notAnswered} /> : <img alt="wrong" src={wrong} />}</span>
        <Button onClick={() => gotoQuestion(u.questionId)}>goto Question</Button>
      </div>
    ))}
  </StyledTakeTestReview>
);

const StyledTakeTestScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;

  > div:first-of-type {
    font-size: ${veryLargeText};

    > span {
      font-size: ${smallText};
    }
  }
`;

interface TakeTestScoreProps {
  correctPct: number;
}
const TakeTestScore = ({ correctPct }: TakeTestScoreProps) => (
  <StyledTakeTestScore>
    Your Score
    <div>
      {correctPct} <span>%</span>
    </div>
  </StyledTakeTestScore>
);

const StyledSummaryResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 50%;

  > div:first-of-type {
    font-size: ${largeText};
    margin-bottom: 1rem;
  }

  > div:not(:first-of-type) {
    font-size: ${regText};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > span {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface ResultSummaryProps {
  userTest: UserTest;
}
const ResultSummary = ({ userTest }: ResultSummaryProps) => (
  <StyledSummaryResults>
    <div>Results</div>
    <div>
      <span>Number of Questions</span> <span>{userTest.questionsTotal}</span>
    </div>
    <div>
      <span>Number Correct</span>
      <span>{userTest.correctAnswers}</span>
    </div>
    <div>
      <span>Number Incorrect</span>
      <span>{userTest.wrongAnswers}</span>
    </div>
    <div>
      <span>Number not Answered</span>
      <span>{userTest.questionsTotal - userTest.questionsAnswered}</span>
    </div>
  </StyledSummaryResults>
);
