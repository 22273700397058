import React, { useContext } from "react";
import { ROUTES, MODULE_TITLES, FULL_ACCESS } from "../constants";
import { StoreContext } from "../contexts/storeContext";
import { StyledNavigationSection, AppNavLink, Button, StyledAppMessage, largeText, deviceSize } from "../components/common";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Welcome } from "../components/login";

export const NavigationSection = () => {
  const location = useLocation();
  const { loggedInUser, isAdmin, appError, appInfo, dissmissAppInfo, activeModule, moduleAccess } = useContext(StoreContext);

  const route = location.pathname;
  if(!loggedInUser) return <></>

  return (
    <>
      <LoggedInBar />
      <StyledNavigationSection>
        <AppNavLink active={route === ROUTES.HOME} page={ROUTES.HOME} text="Home" />
        <AppNavLink
          disabled={!loggedInUser || !activeModule}
          active={route === ROUTES.TESTS}
          page={ROUTES.TESTS}
          text="Tests"
        />
        {activeModule && moduleAccess[activeModule] === FULL_ACCESS &&
          <AppNavLink
            disabled={!loggedInUser || !activeModule}
            active={route === ROUTES.VIDEOS}
            page={ROUTES.VIDEOS}
            text="Videos"
          />
        }

        {activeModule && moduleAccess[activeModule] === FULL_ACCESS &&
          <AppNavLink
            disabled={!loggedInUser || !activeModule}
            active={route === ROUTES.FLASHCARDS}
            page={ROUTES.FLASHCARDS}
            text="Flashcards"
          />
        }
        {isAdmin && <AppNavLink active={route === ROUTES.ADMIN} page={ROUTES.ADMIN} text="Admin" />}
      </StyledNavigationSection>
      {appError && (
        <StyledAppMessage>
          <span>{appError}</span>
          <Button onClick={() => dissmissAppInfo()}>X</Button>
        </StyledAppMessage>
      )}
      {appInfo && (
        <StyledAppMessage isInfo>
          <span>{appInfo}</span>
          <Button onClick={() => dissmissAppInfo()}>X</Button>
        </StyledAppMessage>
      )}
    </>
  );
};

export const ModulesNavigation = () => {
  const { loggedInUser, modules, changeActiveModule, activeModule } = useContext(StoreContext);
  const history = useHistory();
  const route = useLocation().pathname;
  if (!loggedInUser || activeModule === null || modules.length < 2) return null;
  
  const changeActiveModuleHelper = (m: string) => {
    changeActiveModule(m)
    if (route.split('/')[1] === ROUTES.TAKE.split('/')[1]) {
      setTimeout(() => {
        history.push({
          pathname: `${ROUTES.TESTS}`
        })
      })
    }
  }

  return (
    <StyledNavigationSection>
      {modules.map((m: string) => (
        <Button selected={activeModule === m} key={m} onClick={() => changeActiveModuleHelper(m)}>
          {MODULE_TITLES[m]}
        </Button>
      ))}
    </StyledNavigationSection>
  );
};

export const StyledLoggedInBar = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  > div:first-of-type {
    padding: 1rem 0;
    font-size: ${largeText};
  }

  @media ${deviceSize.mobileL} {
    flex-flow: column-reverse;
    margin-top: 0.25rem;
  }
`;

export const LoggedInBar = () => {
  const { moduleTestMeta } = useContext(StoreContext);
  return (
    <StyledLoggedInBar>
      <div>{moduleTestMeta && moduleTestMeta.name}</div>
      <Welcome />
    </StyledLoggedInBar>
  );
};