import React, { useContext } from "react";
import { Login } from "./login";
import styled from "styled-components";
import module1Image from "../assets/life-accident-health_no_bg.png";
import module2Image from "../assets/life-agent_no_bg.png";
import module3Image from "../assets/personal-lines-property-and-casualty_no_bg.png";
import module4Image from "../assets/property-and-casualty_no_bg.png";
import { largeText, deviceSize, AppTitle } from "./common";
import { StoreContext } from "../contexts/storeContext";
import { StoreConextInterface } from "../types";
import { TestDashboard, VideoDashboard, FlashcardsDashboard, StudyGuideDashboard } from "./dashboard";

const StyledHome = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  > div:nth-of-type(2) {
    display: flex;
    flex-flow: row;
    margin: 0 10%;
    justify-content: space-evenly;

    >div:first-of-type{
      display:flex;
      flex-direction: row;
      // justify-content: center;
    }

  }
  @media ${deviceSize.tablet} {
    > div:first-of-type {
      margin-bottom: 25px;
    }
    > div:nth-of-type(2) {
      flex-flow: column;
      justify-content: space-between;
      
      >div:first-of-type{
        margin-bottom: 25px;
      }
    }
  }
`;

export const Home = () => {
  return (
    <StyledHome>
      <AppTitle />
      <div>
        <Login />
        <HomeContent />
      </div>
    </StyledHome>
  );
};

const StyledHomeContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 35px;

  > div:first-of-type {
    margin-bottom: 20px;
    font-size: ${largeText};
  }
  > div:not(:first-of-type) {
    display: flex;
    align-items: flex-end;
    >div {
      display: flex;
      justify-content: center;
      width: 100px;
    }

    >span {
      margin: auto 25px;
    }
  }
  @media ${deviceSize.mobileL} {
    margin-top: 1rem;
  }
`;

const StyledHomeLoggedContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const HomeContent = () => {
  const { loggedInUser, moduleAccess, activeModule } = useContext<StoreConextInterface>(StoreContext);

  return (
    <>
      {loggedInUser && activeModule && moduleAccess[activeModule] && (
        <StyledHomeLoggedContent>
          <TestDashboard />
          <VideoDashboard />
          <FlashcardsDashboard />
          <StudyGuideDashboard />
        </StyledHomeLoggedContent>
      )}
      {!loggedInUser && (
        <StyledHomeContent>
          <div>Complete Study Programs for the Texas Insurance License Exams</div>
          <div>
            <div><img alt="life-accident-health" src={module1Image} /></div>
            <span>General Lines - Life, Accident and Health</span>
          </div>
          <div>
            <div><img alt="life-agent" src={module2Image} /></div>
            <span>Life Agent</span>
          </div>
          <div>
            <div><img alt="personal-lines-property-and-casualty" src={module3Image} /></div>
            <span>General Lines - Property and Casualty</span>
          </div>
          <div>
            <div><img alt="property-and-casualty" src={module4Image} /></div>
            <span>Personal Lines - Property and Casualty</span>
          </div>
        </StyledHomeContent>
      )}
    </>
  );
};
