import { useContext, useEffect, useState } from "react";
import { StoreConextInterface, StudyGuideData, UserTest } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import React from "react";
import { EXAM_TYPES, ROUTES, TEST_ONLY } from "../constants";
import { borderColor, largeText, secondaryBackgroundColor } from "./common";

import { useHistory } from "react-router-dom";
import { STUDY_GUIDES } from "../data-constants";

const StyledTestDashboard = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 6px;
  border: 2px solid ${borderColor};
  padding: 2rem;
  margin: 1rem;

  &:hover {
    background: ${secondaryBackgroundColor};
    cursor: pointer;
  }

  > span:first-of-type {
    font-size: ${largeText};
  }

  > div {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0.25rem 0;

    > span:first-of-type {
      padding-right: 1rem;
    }
  }
`;

export const TestDashboard = () => {
  const history = useHistory();
  const { moduleTestMeta, moduleTestFlashcards, userTestData, activeModule } = useContext<StoreConextInterface>(
    StoreContext,
  );
  if (!moduleTestFlashcards || !moduleTestFlashcards.test) return null;
  const totalQuestions = moduleTestFlashcards.test.length;
  const examsTaken = userTestData.length;
  const totalCategories = moduleTestMeta.categories.length;
  const totalChapters = moduleTestMeta.categories.reduce((p, c) => p + c.chapters.length, 0);
  const bestTotalExam: UserTest | null = findUserTest(EXAM_TYPES.REG_EXAM, userTestData);
  const bestCategoryExamsTaken: UserTest | null = findUserTest(EXAM_TYPES.REG_CAT, userTestData);
  const bestChapterExamsTaken: UserTest | null = findUserTest(EXAM_TYPES.REG_CHP, userTestData);

  return (
    <StyledTestDashboard
      onClick={() =>
        activeModule
          ? history.push({
            pathname: ROUTES.TESTS,
          })
          : null
      }
    >
      <span>Tests:</span>
      <div>
        <span>Total Questions:</span>
        <span>{totalQuestions}</span>
      </div>
      <div>
        <span>Total Categories:</span>
        <span>{totalCategories}</span>
      </div>
      <div>
        <span>Total Chapters:</span>
        <span>{totalChapters}</span>
      </div>
      <div>
        <span>Total Exams Taken:</span>
        <span>{examsTaken}</span>
      </div>
      <div>
        <span>Best Total Exam Score:</span>
        <span>{bestTotalExam && bestTotalExam.correctPct}</span>
      </div>
      <div>
        <span>Best Category Exam Score:</span>
        <span>{bestCategoryExamsTaken && bestCategoryExamsTaken.correctPct}</span>
      </div>
      <div>
        <span>Best Chapter Exam Score:</span>
        <span>{bestChapterExamsTaken && bestChapterExamsTaken.correctPct}</span>
      </div>
    </StyledTestDashboard>
  );
};

const findUserTest = (type: string, userTestData: UserTest[]): UserTest | null => {
  return userTestData.length > 0
    ? userTestData.reduce(
      (p, e) => {
        if (e.type !== type) return p;
        if (!p.correctPct) return e;
        return p.correctPct > e.correctPct ? p : e;
      },
      {} as UserTest,
    )
    : null;
};

const StyledVideoDashboard = styled(StyledTestDashboard)``;

export const VideoDashboard = () => {
  const history = useHistory();
  const { userVideoData, moduleVideos, activeModule, moduleAccess } = useContext<StoreConextInterface>(StoreContext);
  const totalVideos = (moduleVideos && moduleVideos.length) || 0;
  const totalVideosSeen = (userVideoData && userVideoData.filter(v => v.seen).length) || 0;

  if (activeModule && (moduleAccess[activeModule] === undefined || moduleAccess[activeModule] === TEST_ONLY)) {
    return <></>
  }

  return (
    <StyledVideoDashboard
      onClick={() =>
        activeModule
          ? history.push({
            pathname: ROUTES.VIDEOS,
          })
          : null
      }
    >
      <span>Videos:</span>
      <div>
        <span>Total Videos:</span>
        <span>{totalVideos}</span>
      </div>
      <div>
        <span>Total Videos Seen:</span>
        <span>{totalVideosSeen}</span>
      </div>
    </StyledVideoDashboard>
  );
};

const StyledFlashcardsDashboard = styled(StyledTestDashboard)``;

export const FlashcardsDashboard = () => {
  const history = useHistory();
  const { moduleTestFlashcards, activeModule, moduleAccess } = useContext<StoreConextInterface>(StoreContext);

  if (activeModule && (moduleAccess[activeModule] === undefined || moduleAccess[activeModule] === TEST_ONLY)) {
    return <></>
  }

  if (!moduleTestFlashcards || !moduleTestFlashcards.flashcards) return null;
  const totalFlashcards = moduleTestFlashcards.flashcards.length;
  return (
    <StyledFlashcardsDashboard
      onClick={() =>
        activeModule
          ? history.push({
            pathname: ROUTES.FLASHCARDS,
          })
          : null
      }
    >
      <span>Flashcards:</span>
      <div>
        <span>Total Flashcards:</span>
        <span>{totalFlashcards}</span>
      </div>
    </StyledFlashcardsDashboard>
  );
};

const StyledStudyGuidesDashboard = styled(StyledTestDashboard)`
  cursor: default;

  &:hover {
    cursor: default;
    background-color: initial; 
  }

  a:link {
    text-decoration: none
  }
`;

export const StudyGuideDashboard = () => {
  const [chapters, setChapters] = useState([] as StudyGuideData[])
  const { activeModule, moduleAccess } = useContext<StoreConextInterface>(
    StoreContext,
  );

  useEffect(() => {
    if (activeModule)
      setChapters(STUDY_GUIDES[activeModule] as never[] as StudyGuideData[])
  }, [activeModule])

  if (activeModule && (moduleAccess[activeModule] === undefined || moduleAccess[activeModule] === TEST_ONLY)) {
    return <></>
  }

  return (
    <StyledStudyGuidesDashboard>
      <span>Study Guides:</span>
      {chapters.map((g, i) =>
        <div key={i}>

          <a rel="noopener noreferrer" download={g.source} target="_blank" href={require(`../assets/studyguides/${g.source}`)}>
            {g.name}
          </a>
        </div>
      )}
    </StyledStudyGuidesDashboard>
  )
}