import React from "react";
import AuthenticatedRoute from "../firebase/authorized-route";
import { ROUTES } from "../constants";
import { Route, Switch } from "react-router";
import { Admin } from "../components/admin";
import { Flashcards } from "../components/flashcards";
import { Tests } from "../components/tests";
import { TakeTest } from "../components/take-test";
import { Videos } from "../components/videos";
import { Home } from "../components/home";

export const MainSection = () => (
  <>
    <Switch>
      <Route exact path={ROUTES.HOME} component={Home} />
      <AuthenticatedRoute path={ROUTES.VIDEOS} component={Videos} />
      <AuthenticatedRoute path={ROUTES.FLASHCARDS} component={Flashcards} />
      <AuthenticatedRoute path={ROUTES.TESTS} component={Tests} />
      <AuthenticatedRoute path={ROUTES.TAKE} component={TakeTest} />
      <AuthenticatedRoute path={ROUTES.ADMIN} component={Admin} />
      <Route component={Home} />
    </Switch>
  </>
);
