import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../contexts/storeContext";
import { Redirect } from "react-router";
import { ANSWER_LETTERS, ROUTES, SKUS } from "../constants";
import styled from "styled-components";
import { Button, smallText, almostWhite, SelectDropdown, secondaryBackgroundColor } from "./common";
import { Flashcard, Question, Answer, UserPurchases } from "../types";
import { deepClone } from "../helpers/structures";
import { FirebaseContext } from "../contexts/firebaseContext";
import moment from "moment";
import { userInfo } from "os";

const cbpSkus = ['SKU103', 'SKU104', 'SKU105', 'SKU106']

const BUTTONS = {
  TEST: "TEST",
  FLASHCARDS: "FLASHCARDS",
  USERS: "USERS",
};

const StyledAdmin = styled.div`
  display: flex;
  flex-flow: column;

  > div:first-of-type {
    display: flex;
    flex-flow: row;
    margin-bottom: 0.5rem;
  }
`;

export const Admin = () => {
  const { isAdmin } = useContext(StoreContext);
  const { moduleTestFlashcards, updateTestAndFlashcards, activeModule } = useContext(StoreContext);
  const [active, setActive] = useState<string>(BUTTONS.TEST);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [test, setTest] = useState<Question[]>(deepClone<Question[]>(moduleTestFlashcards.test));
  const [flashcards, setFlashcards] = useState<Flashcard[]>(deepClone<Flashcard[]>(moduleTestFlashcards.flashcards));

  const updateTestData = (test: Question[]) => {
    if (!test) return;
    setTest(test);
    setHasChanges(true);
  };
  const updateFlashcardsData = (cards: Flashcard[]) => {
    if (!cards) return;
    setFlashcards(cards);
    setHasChanges(true);
  };

  if (!isAdmin) return <Redirect to={ROUTES.SIGN_IN} />;

  const saveChanges = () => {
    if (activeModule) {
      updateTestAndFlashcards(activeModule, {
        test,
        flashcards,
      });
      setHasChanges(false);
    }
  };

  return (
    <StyledAdmin>
      <div>
        <Button disabled={!hasChanges} onClick={() => saveChanges()}>
          Save Changes
        </Button>
        <Button selected={active === BUTTONS.TEST} onClick={() => setActive(BUTTONS.TEST)}>
          Test
        </Button>
        <Button selected={active === BUTTONS.FLASHCARDS} onClick={() => setActive(BUTTONS.FLASHCARDS)}>
          Flashcards
        </Button>
        <Button selected={active === BUTTONS.USERS} onClick={() => setActive(BUTTONS.USERS)}>
          Users
        </Button>
      </div>
      {BUTTONS.TEST === active && <UpdateTest test={test} update={(data: Question[]) => updateTestData(data)} />}
      {BUTTONS.FLASHCARDS === active && (
        <UpdateFlashcards flashcards={flashcards} update={(data: Flashcard[]) => updateFlashcardsData(data)} />
      )}
      {BUTTONS.USERS === active && <UpdateUsersModules />}
    </StyledAdmin>
  );
};
const StyledInputData = styled.div`
  background: ${secondaryBackgroundColor};
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  padding: 0.25rem;

  > button {
    width: 20px;
    padding: 0;
  }

  > div {
    display: flex;
    flex-flow: row;

    input:first-of-type {
      width: 20px;
      margin: 0 0.25rem;
    }
    input:last-of-type {
      width: 100%;
      margin: 0 0.25rem;
    }
    > span {
      font-size: ${smallText};
      padding: 0.25rem;
    }
  }
  > div:nth-last-of-type(-n+2) {
    margin-top: 0.25rem;
  }
`;

interface UpdateTestProps {
  test: Question[];
  update: Function;
}
const UpdateTest = ({ test, update }: UpdateTestProps) => {
  const [questions, setQuestions] = useState<Question[]>(test);

  const changeQuestion = (id: number, property: string, value: string) => {
    console.log(id, property, value);
    const question: Question | undefined = questions.find(q => q.id === id);
    if (!question) return;
    if (property === "q" || property === "answer" || property === "explanation") {
      question[property] = value;
    }
    setQuestions(questions);
    update(questions);
  };

  const changeQuestionNumericProp = (id: number, property: string, value: string) => {
    const question: Question | undefined = questions.find(q => q.id === id);
    if (!question) return;
    if (property === "chp" || property === "cat") {
      question[property] = Number(value);
    }
    setQuestions(questions);
    update(questions);
  };

  const changeQuestionAnswer = (id: number, index: number, name: string, value: string) => {
    const question: Question | undefined = questions.find(q => q.id === id);
    if (!question) return;
    const answers = question.answers;
    answers[index] = { name, value };
    setQuestions(questions);
    update(questions);
  };

  const createNew = () => {
    questions.push({
      cat: 0,
      chp: 0,
      id: questions.length + 1,
      q: "question",
      answer: "X",
      answers: [{ name: "A", value: "" }, { name: "B", value: "" }, { name: "C", value: "" }, { name: "D", value: "" }],
      explanation: 'Explanation',
    });
    setQuestions(questions);
    update(questions);
  };

  const deleteQuestion = (id: number) => {
    const newQuestions = questions.filter(q => q.id !== id);
    setQuestions(newQuestions);
    update(questions);
  };

  return (
    <>
      {questions.map((q: Question) => (
        <StyledInputData key={q.id}>
          <Button onClick={() => deleteQuestion(q.id)}>X</Button>
          <input type="text" name="q" defaultValue={q.q} onChange={d => changeQuestion(q.id, "q", d.target.value)} />
          {q.answers.map((a: Answer, index: number) => (
            <div key={q.id + index + "anwers"}>
              <span>{a.name}.</span>
              <input
                type="text"
                name={a.value}
                defaultValue={a.value}
                onChange={d => changeQuestionAnswer(q.id, index, a.name, d.target.value)}
              />
            </div>
          ))}
          <div>
            <span>Answer:</span>
            <select
              name="answer"
              defaultValue={q.answer}
              onChange={d => changeQuestion(q.id, "answer", d.target.value)}
            >
              {ANSWER_LETTERS.map(letter =>
                <option key={letter} value={letter}>{letter}</option>
              )}
            </select>
            <span>Category:</span>
            <input
              type="text"
              name="cat"
              defaultValue={String(q.cat)}
              onChange={d => changeQuestionNumericProp(q.id, "cat", d.target.value)}
            />
            <span>Chapter:</span>
            <input
              type="text"
              name="chp"
              defaultValue={String(q.chp)}
              onChange={d => changeQuestionNumericProp(q.id, "chp", d.target.value)}
            />
          </div>
          <div>
            <span>Explanation:</span>
            <input
              type="text"
              name="explanation"
              defaultValue={q.explanation}
              onChange={d => changeQuestion(q.id, "explanation", d.target.value)}
            />
          </div>
        </StyledInputData>
      ))}
      <Button style={{ marginBottom: "1rem" }} onClick={() => createNew()}>
        Add
      </Button>
    </>
  );
};

interface UpdateFlashcardsProps {
  flashcards: Flashcard[];
  update: Function;
}
const UpdateFlashcards = ({ flashcards, update }: UpdateFlashcardsProps) => {
  const [cards, setCards] = useState<Flashcard[]>(flashcards);

  const change = (index: number, property: string, value: string) => {
    if (index < 0 || index > cards.length) return;
    const card: Flashcard = cards[index];
    if (property === "q" || property === "a" || property === "t") card[property] = value;

    setCards(cards);
    update(cards);
  };

  const createNew = () => {
    cards.push({
      q: "",
      a: "",
      t: "",
    });
    setCards(cards);
    update(cards);
  };

  const deleteFlashcard = (q: string) => {
    const newCards = cards.filter(c => c.q !== q);
    setCards(newCards);
    update(newCards);
  };

  return (
    <>
      {cards.map((f: Flashcard, index: number) => (
        <StyledInputData key={f.q}>
          <Button onClick={() => deleteFlashcard(f.q)}>X</Button>
          <input type="text" name="q" defaultValue={f.q} onChange={d => change(index, "q", d.target.value)} />
          <input type="text" name="a" defaultValue={f.a} onChange={d => change(index, "a", d.target.value)} />
          <input type="text" name="t" defaultValue={f.t} onChange={d => change(index, "t", d.target.value)} />
        </StyledInputData>
      ))}
      <Button style={{ marginBottom: "1rem" }} onClick={() => createNew()}>
        Add
      </Button>
    </>
  );
};

const StyledNewUser = styled.div`
  background: ${secondaryBackgroundColor};
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  padding: 0.25rem;

  > div {
    background: ${almostWhite};
    display: flex;
    flex-flow: row;
    align-items: center;

    > button {
      width: 80px;
      padding: 0;
      margin-right: 10px;
    }
    > select {
      width: 100px;
      margin-right: 2rem;
      height: unset;
    }
  }
`;

const StyledFirebaseUser = styled.div`
  background: ${secondaryBackgroundColor};
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  padding: 0.25rem;

  > div:first-of-type {
    display: flex;
    flex-flow: row;

    > button {
      width: 200px;
      margin-right: 2rem;
    }
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-flow: column;

    > div {
      background: ${almostWhite};
      display: flex;
      flex-flow: row;
      align-items: center;

      > button {
        width: 20px;
        padding: 0;
        margin-right: 10px;
      }

      > button:last-of-type {
        width: 40px;
        padding: 0;
        margin-right: 10px;
      }
      > select {
        width: 100px;
        margin-right: 2rem;
        height: unset;
      }

      > span:first-of-type {
        margin-right: 2rem;
      }
    }
  }
`;
const ACTIONS = {
  REAMOVE_MODULE: "REMOVE_MODULE",
  ADD_MODULE: "ADD_MODULE",
};
interface UserModuleData {
  moduleName: string;
  expiration: Timestamp;
}
interface Timestamp {
  seconds: number;
  nanoseconds: number;
}
interface User {
  email: string;
  modules: UserModuleData[];
}

const UpdateUsersModules = () => {
  const { firebase } = useContext(FirebaseContext);
  const { sendPasswordResetEmail, setError, setInfo} = useContext(StoreContext);
  const [fireUsersList, setFireUsersList] = useState<UserPurchases[]>([]);
  const [tilUsers, setTilUsers] = useState<User[]>([]);
  const [addModule, setAddModule] = useState<string>(SKUS[0]);
  const [emailAddress, setEmailAddress] = useState<string>("");

  const updateUserPurchasesListFirebase = (user: UserPurchases) => {
    firebase.doUpdateUserPurchases(user, (e: Error) => {
      if (e) {
        setError(e.message);
      }
      setFireUsersList([]);
    });
  }

  const updateUser = (email: string, moduleName: string, action: string) => {
    if (!email) return;
    const user = fireUsersList.find(u => u.email === email);
    if (!user) return;

    if (action === ACTIONS.ADD_MODULE) {
      // set 6 months expiration
      const seconds = moment
        .utc()
        .add("M", 6)
        .unix();
      const expiration = {
        seconds,
        nanoseconds: 0,
      };
      user[moduleName] = { expiration };
    } else if (action === ACTIONS.REAMOVE_MODULE) {
      delete user[moduleName]
    }

    updateUserPurchasesListFirebase(user)
  };

  const addNewUser = (email: string, moduleName: string) => {
    const seconds = moment
      .utc()
      .add("M", 6)
      .unix();
    const expiration = {
      seconds,
      nanoseconds: 0,
    }

    let user = fireUsersList.find(u => u.email === email);
    if (user) {
      console.log('user found')
      if (user[moduleName]) {
        setInfo(`User with that Email already has SKU: ${moduleName} assigned.`)
        console.log('user already has this sku')
        return
      } else {
        user[moduleName] = { expiration }
      }
      updateUserPurchasesListFirebase(user)
    } else {
      firebase.doAddNewUserPurchases({ email, [moduleName]: { expiration } }, (e: Error) => {
        if (e) setError(e.message);
        setFireUsersList([]);
      });
    }
  };

  useEffect(() => {
    if (fireUsersList.length === 0) {
      try {
        firebase.doRetrieveAllUserIds((fireUsers: UserPurchases[]) => {
          if (!fireUsers) console.log("no user ids retrieved");
          setFireUsersList(fireUsers)
        });
      } catch (e) {
        console.error("could not retrieve users", e);
      }
    } else {
      const userModuleData = fireUsersList.reduce((p: User[], fireUser: UserPurchases) => {
        let user = { email: fireUser.email, modules: [] } as User;
        Object.keys(fireUser).forEach((key: string) => {
          if (key !== "email") {
            const moduleName = key;
            if (SKUS.includes(moduleName)) {
              const exp = fireUser[key] as UserModuleData;
              const expiration = exp.expiration as Timestamp;
              user.modules = [...user.modules, { moduleName, expiration }];
            }
          }
        });
        if (user.modules.length > 0) {
          return [...p, user]
        } else {
          return p
        }
      }, []);
      setTilUsers(userModuleData);
    }
  }, [firebase, fireUsersList]);

  return (
    <>
      {tilUsers.map((u: User) => (
        <StyledFirebaseUser key={u.email}>
          <div>
            <Button onClick={() => sendPasswordResetEmail(u.email)}>Send Password Reset Email</Button>
            <span>{u.email}</span>
          </div>
          <div>
            {u.modules.map((m: UserModuleData) => (
              <div key={m.moduleName}>
                <Button onClick={() => updateUser(u.email, m.moduleName, ACTIONS.REAMOVE_MODULE)}>X</Button>
                <span>{m.moduleName}</span>
                <span>expires: {moment.unix((m.expiration || {}).seconds).format("ddd, MMM Do YYYY, h:mm a")}</span>
              </div>
            ))}
            <div>
              <Button onClick={() => updateUser(u.email, addModule, ACTIONS.ADD_MODULE)}>Add</Button>
              <SelectDropdown defaultValue={""} options={SKUS} onChange={(value: string) => setAddModule(value)} />
              With 6 months expiration
            </div>
          </div>
        </StyledFirebaseUser>
      ))}
      <StyledNewUser>
        <input type="text" name="email" placeholder="email address" onChange={d => setEmailAddress(d.target.value)} />
        <div>
          <Button onClick={() => addNewUser(emailAddress, addModule)}>Add User</Button>
          <SelectDropdown defaultValue={""} options={SKUS} onChange={(value: string) => setAddModule(value)} />
          With 6 months expiration
        </div>
      </StyledNewUser>
    </>
  );
};
