export interface UserPurchases {
  [name: string]: string | Object;
}

export interface UserQuestionAnswers {
  categoryId: number;
  chapterId: number;
  questionId: number;
  answer: string;
  isCorrect: boolean;
}

export interface UserTest {
  chapterId: number;
  timestamp: number;
  categoryId: number;
  randomized: boolean;
  correctPct: number;
  correctAnswers: number;
  wrongPct: number;
  wrongAnswers: number;
  questions: UserQuestionAnswers[];
  questionsTotal: number;
  questionsAnswered: number;
  type: string;
  elapsed: number;
}

export interface ModuleVideo {
  [moduleName: string]: VideoData[];
}

export interface StudyGuideData {
  name: string;
  source: string;
}
export interface ModuleStudyGuides {
  [moduleName: string]: StudyGuideData[]
}
export interface VideoData {
  chapter: string;
  link: string;
}
export interface SeenVideo {
  id: string;
  seen: boolean;
  timestamp: number;
}

export interface UserModuleTestInfo {
  [module: string]: {
    tests: UserTest[];
    videos: SeenVideo[];
  };
}

export interface Flashcard {
  q: string;
  a: string;
  t: string;
}
export interface Flashcards {
  flashcards: Flashcard[];
}

export interface Answer {
  name: string;
  value: string;
}
export interface Question {
  cat: number;
  chp: number;
  id: number;
  q: string;
  answer: string;
  answers: Answer[];
  explanation: string;
}
export interface ModuleTestFlashcards {
  test: Question[];
  flashcards: Flashcard[];
}
export interface ModuleData {
  [module: string]: ModuleTestFlashcards;
}
export interface Chapter {
  id: number;
  cat: number;
  name: string;
}
export interface Category {
  id: number;
  name: string;
  randNum: number;
  chapters: Chapter[];
}
export interface Module {
  tag: string; // purchase code
  name: string;
  categories: Category[];
}
export interface ModuleTitles {
  [module: string]: string;
}
export interface Modules {
  [module: string]: Module;
}
export interface LoggedInUser {
  additionalUserInfo?: {
    providerId: string;
    isNewUser: false;
  };
  credential?: any;
  operationType?: string;
  user: {
    uid: string;
    displayName: string;
    photoUrl: string;
    emailVerified: boolean;
    phoneNumber: string;
    email: string;
    providerData: {
      providerId: string;
    };
    createdAt: string;
    lastLoginAt: string;
    authDomain: string;
  };
}

export interface ModuleAccess {
  [module: string] : string
}

export enum ActionType {
  init = "INIT",
  updateTest = "UPDATE_TEST",
  updateFlashcards = "UPDATE_FLASHCARDS",
  addTest = "ADD_TEST",
  addVideo = "ADD_VIDEO",
  update = "UPDATE",
  deleteTest = "DELETE_TEST",
  deleteTests = "DELETE_TESTS",
  deleteVideo = "DELETE_VIDEO",
}

export enum UserActionType {
  SET_USER = "SET_USER",
}
export interface Action {
  type: ActionType;
}

export interface ModuleTestAction extends Action {
  payload: { moduleName: string; test: Question[] };
}

export interface ModuleFlashcardAction extends Action {
  payload: { moduleName: string; flashcards: Flashcard[] };
}

export interface ModuleDataAction extends Action {
  payload: { moduleData: ModuleData };
}

export interface UserInitLoadAction extends Action {
  payload: { userModuleTestInfo: UserModuleTestInfo };
}
export interface UserAddTestResultAction extends Action {
  payload: { moduleName: string; test: UserTest };
}
export interface UserAddVideoResultAction extends Action {
  payload: { moduleName: string; video: SeenVideo };
}
export interface UserDeleteTestResultAction extends Action {
  payload: { moduleName: string; timestamp: number };
}
export interface UserDeleteTestsResultAction extends Action {
  payload: { moduleName: string; timestamps: number[] };
}
export interface UserDeleteVideoResultAction extends Action {
  payload: { moduleName: string; id: string };
}
export interface IUserAction {
  type: ActionType;
  payload: object;
}

export interface TestContextInterface {
  moduleTests: ModuleData;
  setTestModules: Function;
  updateTestFlashcardsModule: Function;
}

export interface UserAccess {
  module: string;
  expiration: Date;
}

export interface UserContextInterface {
  userModuleTestInfo: UserModuleTestInfo;
  getUserTestData: Function;
  clearTestResult: Function;
  clearTestResults: Function;
  addUserTest: Function;
  addUserVideo: Function,
  removeUserVideo: Function,
}

export interface StoreConextInterface {
  loginUser: (email: string, password: string) => void;
  logoutUser: () => void;
  loggedInUser?: LoggedInUser | undefined;
  loginError?: string;
  isLoading: boolean;
  modules: string[];
  moduleAccess: ModuleAccess;
  activeModule: string | null;
  changeActiveModule: Function;
  isAdmin: boolean;
  appError: string;
  appInfo: string;
  userTestData: UserTest[];
  userVideoData: SeenVideo[];
  moduleVideos: VideoData[];
  moduleTestFlashcards: ModuleTestFlashcards;
  moduleTestMeta: Module;
  removeTestResult: Function;
  removeTestResults: Function;
  addTestResult: Function;
  addVideo: Function;
  removeVideo: Function;
  categoryQuestionCount: Function;
  sendPasswordResetEmail: Function,
  dissmissAppInfo: Function,
  updateTestAndFlashcards: Function,
  setError: Function,
  setInfo: Function
}
