import { Modules, ModuleTitles } from "./types";

export const AdminAccounts = ['NHyF8KcXGmMIpBvOzupMzGGbnAa2', 'idnZvJW1I1RympYRA7eS0MRK8dU2', 'HoNTbMWtx1YJLrDbfILHRXj52hp2'];
export const ROUTES = {
  HOME: "/",
  VIDEOS: "/videos",
  SIGN_UP: "/signup",
  SIGN_IN: "/signin",
  FLASHCARDS: "/flashcards",
  TESTS: "/tests",
  TAKE: "/take/:cat/:rand/:exist/:chap",
  ACCOUNT: "/account",
  ADMIN: "/admin",
  PASSWORD_FORGET: "/pw-forget",
};

export const COLLECTIONS = {
  USER_MODULES: "users",
  USER_DATA: "userdata",
  MODULES: "modules",
};

export const EXAM_TYPES = {
  RAND_CAT: 'RAND_CAT',
  RAND_EXAM: 'RAND_EXAM',
  REG_CAT: 'REG_CAT',
  REG_EXAM: 'REG_EXAM',
  REG_CHP: 'REG_CHP',
  CORR_REVIEW: 'CORR_REVIEW',
  MISS_REVIEW: 'MISS_REVIEW',
  SKIP_REVIEW: 'SKIP_REVIEW',
}

export const PASSABLE_LIMIT = 70;
export const MAX_USER_TESTS_ALLOWED = 40;
export const MODULE1 = "MODULE1";
export const MODULE2 = "MODULE2";
export const MODULE3 = "MODULE3";
export const MODULE4 = "MODULE4";
export const MODULE5 = "MODULE5";

export const ALL_QUESTIONS_LEFT = "ALL Questions Remaining";
export const MODULE_TITLES: ModuleTitles = {
  [MODULE1]: "General Lines Life, Accident and Health",
  [MODULE2]: "Life Agent",
  [MODULE3]: "General Lines Property and Casualty",
  [MODULE4]: "Personal Lines Property and Casualty",
  [MODULE5]: "module one",
};

export const SKUS = ['SKU1', 'SKU2', 'SKU3', 'SKU4', 'SKU5', 'GLLAH08', 'LF07', 'GLPC06', 'PLPC05'];

export const FULL_ACCESS = 'FULL_ACCESS'
export const TEST_ONLY = 'TEST_ONLY'

export const ANSWER_LETTERS = ['A', 'B', 'C', 'D']

export const MODULE_CATEGORIES: Modules = {
  [MODULE1]: {
    tag: "INSTX-LAH05",
    name: "General Lines Life, Accident and Health",
    categories: [
      {
        id: 1,
        name: "Life Insurance General Lines",
        randNum: 50,
        chapters: [
          { id: 1, cat: 1, name: "LIFE INSURANCE - TYPES OF POLICIES" },
          { id: 2, cat: 1, name: "LIFE INSURANCE - POLICY RIDERS, PROVISIONS, OPTIONS, AND EXCLUSIONS" },
          {
            id: 3,
            cat: 1,
            name: "LIFE INSURANCE - COMPLETING THE APPLICATION, UNDERWRITING, AND DELIVERING THE POLICY",
          },
          { id: 4, cat: 1, name: "LIFE INSURANCE - TAXES, RETIREMENT, AND OTHER INSURANCE CONCEPTS" },
        ],
      },
      {
        id: 2,
        name: "Accident and Health General Lines",
        randNum: 100,
        chapters: [
          { id: 5, cat: 2, name: "ACCIDENT & HEALTH – TYPES OF POLICIES" },
          { id: 6, cat: 2, name: "ACCIDENT & HEALTH – POLICY PROVISIONS, CLAUSES, AND RIDERS" },
          { id: 7, cat: 2, name: "ACCIDENT & HEALTH – SOCIAL INSURANCE" },
          { id: 8, cat: 2, name: "OTHER INSURANCE CONCEPTS" },
          { id: 9, cat: 2, name: "ACCIDENT & HEALTH – FIELD UNDERWRITING PROCEDURES" },
        ],
      },
      {
        id: 3,
        name: "Texas Specific Content",
        randNum: 30,
        chapters: [
          { id: 10, cat: 3, name: "LIFE AND HEALTH – TEXAS STATUTES AND RULES COMMON TO LIFE AND HEALTH INSURANCE" },
          { id: 11, cat: 3, name: "LIFE AND HEALTH – TEXAS STATUTES AND RULES PERTINENT TO LIFE INSURANCE ONLY" },
          {
            id: 12,
            cat: 3,
            name: "LIFE AND HEALTH – TEXAS STATUTES AND RULES PERTINENT TO ACCIDENTAL AND HEALTH INSURANCE ONLY",
          },
          {
            id: 13,
            cat: 3,
            name: "LIFE AND HEALTH – TEXAS STATUES AND RULES PERTINENT TO HEALTH MAINTENANCE ORGANIZATIONS",
          },
        ],
      },
    ],
  },
  [MODULE2]: {
    tag: "INSTX-Life01",
    name: "Life Agent",
    categories: [
      {
        id: 1,
        name: "Life Insurance",
        randNum: 50,
        chapters: [
          { id: 1, cat: 1, name: "LIFE INSURANCE - TYPES OF POLICIES" },
          { id: 2, cat: 1, name: "LIFE INSURANCE - POLICY RIDERS, PROVISIONS, OPTIONS, AND EXCLUSIONS" },
          {
            id: 3,
            cat: 1,
            name: "COMPLETING THE APPLICATION, UNDERWRITING, AND DELIVERING THE POLICY",
          },
          { id: 4, cat: 1, name: "LIFE INSURANCE - TAXES, RETIREMENT, AND OTHER INSURANCE CONCEPTS" },
          { id: 5, cat: 1, name: "LIFE INSURANCE - FIELD UNDERWRITING PROCEDURES" },
        ],
      },
      {
        id: 2,
        name: "Life Agent Texas Specific Content",
        randNum: 35,
        chapters: [
          { id: 6, cat: 2, name: "LIFE AGENT - TEXAS STATUTES AND RULES COMMON TO LIFE INSURANCE" },
          { id: 7, cat: 2, name: "LIFE AGENT - TEXAS STATUTES AND RULES PERTINENT TO LIFE INSURANCE" },
        ],
      },
    ],
  },
  [MODULE3]: {
    tag: "INSTX-PC06",
    name: "General Lines Property and Casualty",
    categories: [
      {
        id: 1,
        name: "Part I",
        randNum: 100,
        chapters: [
          { id: 1, cat: 1, name: "INTRO TO BASIC HOME AND COMMERCIAL POLICIES" },
          { id: 2, cat: 1, name: "INSURANCE TERMS AND RELATED CONCEPTS" },
          {
            id: 3,
            cat: 1,
            name: "POLICY PROVISIONS AND CONTRACT LAW",
          },
          { id: 4, cat: 1, name: "TYPES OF POLICIES, BONDS, AND RELATED TERMS" },
        ],
      },
      {
        id: 2,
        name: "Part II",
        randNum: 25,
        chapters: [
          { id: 5, cat: 2, name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- TDI POWERS" },
          { id: 6, cat: 2, name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- LICENSING" },
          {
            id: 7,
            cat: 2,
            name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- MARKETING RULES",
          },
          { id: 8, cat: 2, name: "TEXAS STATUTES AND RULES PERTINENT TO PROPERTY AND CASUALTY INSURANCE" },
        ],
      },
    ],
  },
  [MODULE4]: {
    tag: "INSTX-PersPC55",
    name: "Personal Lines Property and Casualty",
    categories: [
      {
        id: 1,
        name: "Part I",
        randNum: 75,
        chapters: [
          { id: 1, cat: 1, name: "PROPERTY AND CASUALTY – TYPES OF POLICIES" },
          { id: 2, cat: 1, name: "TYPES OF CASUALTY POLICIES – PERSONAL AUTO" },
          {
            id: 3,
            cat: 1,
            name: "INSURANCE TERMS AND RELATED CONCEPTS",
          },
          { id: 4, cat: 1, name: "POLICY PROVISIONS AND CONTRACT LAW" },
        ],
      },
      {
        id: 2,
        name: "Part II",
        randNum: 25,
        chapters: [
          { id: 5, cat: 2, name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- TDI POWERS" },
          { id: 6, cat: 2, name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- LICENSING" },
          {
            id: 7,
            cat: 2,
            name: "TEXAS STATUTES AND RULES COMMON TO PROPERTY AND CASUALTY INSURANCE- MARKETING RULES",
          },
          { id: 8, cat: 2, name: "TEXAS STATUTES AND RULES PERTINENT TO PERSONAL LINES" },
        ],
      },
    ],
  },
}