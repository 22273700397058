import React, { useContext, useState } from "react";
import { StoreConextInterface, VideoData } from "../types";
import styled from "styled-components";
import { StoreContext } from "../contexts/storeContext";
import ReactPlayer from "react-player";
import { highlight, deviceSize, smallText, primarytextColor, inverseText, secondaryBackgroundColor } from "./common";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";
import { ROUTES, TEST_ONLY } from "../constants";

const StyledVideoPage = styled.div`
  font-size: ${smallText};
  display: flex;
  flex-flow: row;
  margin: 1rem 0;
  justify-content: center;

  > div:first-of-type {
    min-width: 640px;
  }

  @media ${deviceSize.tablet} {
    flex-flow: column;
    > div:first-of-type {
      width: 100%;
      min-width: unset;
    }
  }
`;

interface StyledVideoProps {
  seen: boolean;
}

const StyledVideo = styled.div`
  ${(props: StyledVideoProps) =>
    css`
      background-color: ${props.seen ? highlight : secondaryBackgroundColor}
      border-radius: 2px;
      padding: 0.25rem;
      display: flex;
      margin: 0.25rem;
      cursor: pointer;

      > svg {
          align-self: flex-start;
          margin-right: 0.25rem;
        }
      
      > span:first-of-type {
        color: ${inverseText};
        flex-grow: 2;
      }

      > span:nth-of-type(2) {
        font-size: ${smallText};
        color: ${primarytextColor};

        &:hover {
          color: ${inverseText};
        }
      }
    `};
`;

export const Videos = () => {
  const { addVideo, userVideoData, moduleVideos, activeModule, moduleAccess } = useContext<StoreConextInterface>(StoreContext);
  const videos = moduleVideos;
  const [video, setVideo] = useState<VideoData>(videos[0]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  
  if (activeModule && moduleAccess[activeModule] === TEST_ONLY) return <Redirect to={ROUTES.SIGN_IN} />;
  
  const setUserSeenVideo = (video: VideoData, seen: boolean) => {
    const value = { id: video.chapter, seen, timestamp: new Date().getTime() };
    addVideo(value);
  };
  const setVideoAndPlay = (video: VideoData) => {
    setVideo(video);
    setPlaying(true);
    setUserSeenVideo(video, true);
  };
  return (
    <StyledVideoPage>
      <div>
        <ReactPlayer
          width={"100%"}
          controls={true}
          url={video.link}
          playing={playing}
          onError={setError}
          muted={!playing}
        ></ReactPlayer>
        {error && <span>{error}</span>}
      </div>
      <div>
        {videos.map((video) => (
          <VideoItem
            key={video.chapter}
            video={video}
            seenVideo={Boolean((userVideoData.find((v) => v.id === video.chapter) || { seen: false }).seen)}
            setVideoAndPlay={setVideoAndPlay}
            setUserSeenVideo={setUserSeenVideo}
          />
        ))}
      </div>
    </StyledVideoPage>
  );
};

interface VideoItemProps {
  video: VideoData;
  seenVideo: boolean;
  setVideoAndPlay: Function;
  setUserSeenVideo: Function;
}

export const VideoItem = ({ video, seenVideo, setVideoAndPlay, setUserSeenVideo }: VideoItemProps) => {
  return (
    <StyledVideo
      seen={seenVideo}
      onClick={() => {
        setVideoAndPlay(video);
      }}
    >
      <FontAwesomeIcon icon={seenVideo ? faArrowCircleRight : faArrowRight} />
      <span>Chapter {video.chapter}</span>
      {seenVideo && (
        <span
          onClick={(event: any) => {
            setUserSeenVideo(video, false);
            event.stopPropagation();
          }}
        >
          X
        </span>
      )}
    </StyledVideo>
  );
};
