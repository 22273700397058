import { UserTest } from "../types";

export const randomPicker = (itemArray: number[], numPick: number): number[] => {
  let pickedValues: number[] = shuffle(itemArray);
  return pickedValues.map(v => v).slice(0, numPick);
 };

function shuffle(itemArray: number[]) {
  var currentIndex = itemArray.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = itemArray[currentIndex];
    itemArray[currentIndex] = itemArray[randomIndex];
    itemArray[randomIndex] = temporaryValue;
  }

  return itemArray;
}

export const trimTestCollection = (userTests: UserTest[], maxValue: number): UserTest[] => {
  const sorted = userTests.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
  return sorted.slice(0, maxValue);
};

export function deepClone<T>(value: Object): T {
  return JSON.parse(JSON.stringify(value));
}
