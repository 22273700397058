import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { EXAM_TYPES, ROUTES } from "../constants";
import { StoreContext } from "../contexts/storeContext";
import { StoreConextInterface, UserQuestionAnswers } from "../types";
import { ChartJsDoughnutComponent } from "./test-chartjs-doughnut";

interface QuestionIdsObject {
  correct: Set<number>,
  missed: Set<number>,
  skipped: Set<number>
}

interface ChapterHistoryChartProps {
  chapter: number,
  numberOfTestsConsidered: number,
  setNumberOfTestsConsidered: Function
  chartLabel?: string
}

const getQuestionsWithAnswerType = (fullList: QuestionIdsObject, typeIndex: number) => {
  let list: number[] = []
  switch (typeIndex) {
    case 0:
      list.push(...Array.from(fullList.correct))
      break;
    case 1:
      list.push(...Array.from(fullList.missed))
      break;
    case 2:
      list.push(...Array.from(fullList.skipped))
      break;
    case 3:
      list.push(...Array.from(fullList.missed), ...Array.from(fullList.skipped))
      break;
    default:
      throw new Error("Unidentified Question Result Type");
  }

  return list
}

export const ChapterHistoryChart = (props: ChapterHistoryChartProps) => {
  const history = useHistory();
  const { userTestData } = useContext<StoreConextInterface>(StoreContext);

  let reviewQuestionIds: QuestionIdsObject = {
    correct: new Set(),
    missed: new Set(),
    skipped: new Set()
  }

  // gather historic test data 
  const testHistory =
    userTestData
      .filter(x => x.chapterId === props.chapter && x.type)
      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))

  let reachedMissedReview: boolean = false
  let reachedSkippedReview: boolean = false
  let excemptQuestions: Set<number> = new Set()
  let allQuestions: Set<number> = new Set()

  const getQuestionsToBeReviewed = (isReview: boolean, questions: UserQuestionAnswers[]) => {
    questions.forEach(question => {

      allQuestions.add(question.questionId)

      if (question.isCorrect) {

        if (!reviewQuestionIds.missed.has(question.questionId)) {
          reviewQuestionIds.correct.add(question.questionId)
          // if its a review exam add question to excempt list to prevent the 
          // question being added to a review set if question was missed / skipped
          // in an exam taken previously
          if (isReview) {
            excemptQuestions.add(question.questionId)
          }
        }
      }

      else if (question.answer !== '') {
        // if current question was excempt from a review exam that was taken 
        // after the current test do not add it to the review set
        if (!excemptQuestions.has(question.questionId)) {
          reviewQuestionIds.correct.delete(question.questionId)
          reviewQuestionIds.missed.add(question.questionId)
        }
      }
    });
  }

  // If for loop reaches both a review and missed test stop the
  // loop as the review tests will contain all relevant data from previous tests
  let normalTestsConsidered: number = 0
  for (let ti = 0; ti < testHistory.length && props.numberOfTestsConsidered > normalTestsConsidered && !(reachedMissedReview && reachedSkippedReview); ti++) {
    const testTaken = testHistory[ti];

    switch (testTaken.type) {
      case EXAM_TYPES.REG_CHP:
        normalTestsConsidered++
        getQuestionsToBeReviewed(false, testTaken.questions)
        break;

      case EXAM_TYPES.MISS_REVIEW:
        reachedMissedReview = true
        getQuestionsToBeReviewed(true, testTaken.questions)
        break;

      case EXAM_TYPES.SKIP_REVIEW:
        reachedSkippedReview = true
        getQuestionsToBeReviewed(true, testTaken.questions)
        break;

      default:
        break;
    }
  }

  // remove missed and skipped questions form allQuestionsIdsList to find the
  // ids of questions that were answered correctly
  reviewQuestionIds.skipped = allQuestions
  const allQuestionList = Array.from(allQuestions)
  for (let i = 0; i < allQuestionList.length; i++) {
    const questionId = allQuestionList[i];
    if (reviewQuestionIds.correct.has(questionId) || reviewQuestionIds.missed.has(questionId)) {
      reviewQuestionIds.skipped.delete(questionId)
    }
  }

  const percentageCorrect =
    Math.round(100 *
      (reviewQuestionIds.correct.size /
        (reviewQuestionIds.correct.size + reviewQuestionIds.missed.size + reviewQuestionIds.skipped.size)
      )
    )

  const goToReviewTest = (questionIds: QuestionIdsObject, typeIndex: number) => {
    const reviewQuestionIdsSet = getQuestionsWithAnswerType(questionIds, typeIndex)

    // chartjs api throws error if this codeblock is outside setTimeout
    setTimeout(() => {
      history.push({
        pathname: `${ROUTES.TAKE.replace(":cat", String(0))
          .replace(":rand", String(0))
          .replace(":exist", String(0))
          .replace(":chap", String(props.chapter))
          }`,
        state: {
          type: typeIndex,
          questionSet: reviewQuestionIdsSet
        }
      })
    })
  }

  return (
    <ChartJsDoughnutComponent
      handleEvent={
        (a: any, chartJsEvent: any) => {
          if (chartJsEvent[0]) goToReviewTest(reviewQuestionIds, chartJsEvent[0]._index)
        }}
      insideText={percentageCorrect}
      insideTextUnit={'Correct'}
      data={[
        reviewQuestionIds.correct.size,
        reviewQuestionIds.missed.size,
        reviewQuestionIds.skipped.size
      ]}
      labels={['Correct', 'Missed', 'Not Answered']}
      chartLabel={props.chartLabel}
    ></ChartJsDoughnutComponent>
  )
}
