import React, { useState } from 'react';
import styled from 'styled-components'
import { Button, deviceSize, secondaryBackgroundColor } from './common';
import { ChapterHistoryChart } from './test-chapter-history-chart';
import { TestHistory } from './test-history';

const StyledChapterStatsContainer = styled.div`
  margin-top: 10px;

  >button:nth-of-type(1){
    width: 100%;
    margin: 0;
  }

`

const StyledCollapsableDiv = styled.div`
  overflow: hidden;
  transition: 
    max-height .3s ease-in-out,
    padding-top .3s ease-in-out;
  border: ${secondaryBackgroundColor} 1px solid;
  padding: 7px;
  
  padding-top: ${(props: any) =>
    props.open ? '10px' : '0'
  };

  max-height: ${(props: any) =>
    props.open ? '1000px' : '0'
  };

  > div:nth-child(1){
    text-align: center;
    margin: 10px;
    > div{
      margin-top: 0;
    }
  }

  > div:nth-child(2){
    display: flex;
    justify-content: space-between;
    
    > div{
      width: 50%;
      margin:10px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      button{
        width: 100%;
        margin: 10px 0;
      }

      >div:nth-child(1){
        min-width: 180px;
        max-width: 275px;
        margin: auto;
      }
    }
    
    @media ${deviceSize.tablet} {
      flex-direction: column;
      > div{
        width: 100%;
      }
    }
  }

  > div:nth-child(3){
    display: flex;
    justify-contents: space-evenly;

    button{
      
      width:48%;
    }
  }

`

interface QuestionOutcomeCounts {
  correct: number,
  missed: number,
  unanswered: number
}

interface TestQuestionsOutcomeCount {
  [key: number]: QuestionOutcomeCounts
}

interface QuestionIdsObject {
  correct: number[],
  missed: number[],
  unanswered: number[]
}

interface CollapsableDivProps {
  open: boolean,
  chapter: number,
}

interface ChapterStatsProps {
  chapter: number,
  includeTestCount?: number
}

const CollapsableDiv = (props: CollapsableDivProps) => {
  const [numberOfTestsConsidered, setNumberOfTestsConsidered] = useState<number>(5);
  
  return (
    <StyledCollapsableDiv open={props.open}>
      <div>Showing results for the last <b>{numberOfTestsConsidered}</b> test attempts.</div>
      <div>
        <div>
          <ChapterHistoryChart
            chapter={props.chapter}
            numberOfTestsConsidered={numberOfTestsConsidered}
            setNumberOfTestsConsidered={setNumberOfTestsConsidered}
          ></ChapterHistoryChart>
        </div>
      </div>
      <TestHistory chapter={props.chapter}></TestHistory>
    </StyledCollapsableDiv>

  )
}

export const ChapterStatsContainer = (props: ChapterStatsProps) => {
  const [collapse, setCollapse] = useState<boolean>(false);

  return (
    <StyledChapterStatsContainer>
      <Button onClick={() => { setCollapse(!collapse) }}>{!collapse ? "Show" : "Hide"} Chapter History</Button>
      <CollapsableDiv
        open={collapse}
        chapter={props.chapter}
      ></CollapsableDiv>
    </StyledChapterStatsContainer>
  )
}
